import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/analytics'
import {refreshSubData} from './refreshSubData'

export const choosePlan = (firestore, sourceToken, plan, data, isCancel = false) => dispatch => {
  const mixpanel = require('mixpanel-browser')
  let cusID
  dispatch({
    type: 'STORE_PAYLOAD',
    payload: {
      isLoading: true,
    }
  })
  const patient_id = firebase.auth().currentUser.uid
  const setSubURL = process.env.REACT_APP_URL + 'sonnetStripeSetSubscription'

  const myHeaders = new Headers();
  myHeaders.append('Content-Type', 'application/json');

  if (isCancel) {
    const rawSetSub = JSON.stringify(
      {
        patient_id: patient_id,
        Goal: 'cancel',
      }
    )
    const requestOptions2 = {
      method: 'POST',
      headers: myHeaders,
      body: rawSetSub,
      redirect: 'follow'
    };

    fetch(setSubURL, requestOptions2)
          .then(response => response.json())
          .then(result2 => {
            dispatch(refreshSubData(firestore, patient_id))
            dispatch({
              type: 'STORE_PAYLOAD',
              payload: {
                isSettings: false,
                isLoading: false,
                userMessage: 'Your subscription has been cancelled. You can continue using the application until the end of your current billing cycle.',
                notificationType: 2,
                notificationIcon: 'check',
              }
            })
          })
          .catch((error) => {
            console.log('error', error)
            dispatch({
              type: 'STORE_PAYLOAD',
              payload: {
                isLoading: false,
                userMessage: 'There was an error cancelling your subscription. Please try again and contact us if the problem persists.',
                notificationType: 3,
                notificationIcon: 'warning'
              }
            })
          });
  } else if (!data || !data['cusID'] || data['cusID'] === 'None') { // New subscription
    const subNum = (data['subNum'] ? data['subNum'] : 1)
    const cusID = data['cusID']
    const sourceID = sourceToken['id']
    const orgID = data['orgId']
    const name = data.name
    const email = data.email
    const rawSetSub = JSON.stringify(
        {
          'Email': email,
          'Goal': 'new',
          'patient_id': patient_id,
          'Name': name,
          'OrgId': orgID,
          'Source': sourceID,
          'SubItem': plan,
          'SubNum': subNum,
        }
    );
      const requestOptions2 = {
        method: 'POST',
        headers: myHeaders,
        body: rawSetSub,
        redirect: 'follow'
      };

      fetch(setSubURL, requestOptions2)
          .then(response => response.json())
          .then(result2 => {
            firebase.analytics().logEvent('PatientSubbed', {
              source: "Patient Portal Sonnet",
            })
            dispatch(refreshSubData(firestore, patient_id))
            mixpanel.track('Plan Sucessfully Chosen', {'plan':plan})
            dispatch({
              type: 'STORE_PAYLOAD',
              payload: {
                isSettings: false,
                isLoading: false,
                userMessage: 'Your subscription has been updated',
                notificationType: 2,
                notificationIcon: 'check',
              }
            })
          })
          .catch((error) => {
            console.log('error', error)
            const mixpanel = require('mixpanel-browser')
            mixpanel.track(
                'Plan Selection Failure',
                {
                  error: error,
                }
            )
            dispatch({
              type: 'STORE_PAYLOAD',
              payload: {
                isLoading: false,
                userMessage: 'There was an error updating your subscription',
                notificationType: 3,
                notificationIcon: 'warning'
              }
            })
          })
  } else { // Replacement subscription
    const subNum = (data['subNum'] ? data['subNum'] : 1)
    const cusID = data['cusID']
    const sourceID = sourceToken['id']
    const orgID = data['orgId']
    const name = data.name
    const email = data.email
      const rawSetSub2 = JSON.stringify({
        'SubItem': plan,
        'SubNum': subNum,
        'patient_id': patient_id,
        'Goal': 'replace',
        'Source': sourceID
      });

      const requestOptions2 = {
        method: 'POST',
        headers: myHeaders,
        body: rawSetSub2,
        redirect: 'follow'
      };

      fetch(setSubURL, requestOptions2)
          .then(response => response.json())
          .then(result2 => {
            mixpanel.track('Plan Successfully Updated', {plan: plan})
            dispatch(refreshSubData(firestore, patient_id))
            dispatch({
              type: 'STORE_PAYLOAD',
              payload: {
                isSettings: false,
                isLoading: false,
                userMessage: 'Your subscription has been updated',
                notificationType: 2,
                notificationIcon: 'check'
              }
            })
          })
          .catch(error => {
            console.log('error', error)
            mixpanel.track('Plan Update Failure', {error: error})
            dispatch({
              type: 'STORE_PAYLOAD',
              payload: {
                isLoading: false,
                userMessage: 'There was an error updating your subscription',
                notificationType: 3,
                notificationIcon: 'warning'
              }
            })
          });
  }
}
