import React, {Fragment, Component} from 'react'
import {BrowserRouter, Route} from 'react-router-dom'
import {withCookies} from 'react-cookie'
import {connect} from 'react-redux'
import {createFirebaseApp} from '../actions/firebaseAction'
import {signIn} from '../actions/Auth/signIn'
import {storePayload} from '../actions/storePayload'
import WithNav from './WithNav'
import {storeLocalStorageTestSets} from
  '../actions/storeLocalStorageTestSetsAction.js'
import Auth from './Auth'
import ImageUpload from './ImageUpload'
import BodyPart from './BodyPart'
import Onboard from './Onboard'
import ChoosePlan from './ChoosePlan'
import Collage from './Collage'
import TestSet from './TestSet'
import Test from './Test'
import LinkPhysician from './LinkPhysician'
import FullLoadingScreen from './FullLoadingScreen'
import {storePatientData} from '../actions/storePatientDataAction'
import 'firebase/auth'
import 'firebase/database'
import 'firebase/firestore'
import 'firebase/storage'
import Settings from './Settings'
import {Notification} from '@anishp16/lazarus-cds'
import '@anishp16/lazarus-cds/dist/index.css'
import helpers from '../helpers'

// Images
import Check from '../Images/check.svg'
import Warning from '../Images/yellowWarning.svg'

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      patientName: 'Patient Name',
      pricingModal: false,
      slideIn: false, // modal animation
      orientationCounter: 0,
      isLoading: false,
      isSignedIn: true,
      isNotificationVisible: true,
      pages: {
        authPage: true,
        instructionsPage: false,
        checklistPage: false,
        collagePage: false,
        bodyPartPage: false,
        bodyPartPage2: false, // zoomed in view
        testSetPage: false,
        imageUploadPage: false,
        cropperPage: false,
        loadingScreenPage: false,
        resultPage: false,
        errorPage: false, // if query string doesn't have necessary variables
        patientFormPage: false,
        onboardPage: false,
        choosePlanPage: false,
        linkPhysician: false,
      },
    }

    this.changePage = this.changePage.bind(this)
    this.setAppState = this.setAppState.bind(this)
    this.togglePage = this.togglePage.bind(this)

    var mixpanel = require('mixpanel-browser');
    mixpanel.init(process.env.REACT_APP_MIX_PANEL_TOKEN);
    mixpanel.track('User Opened Sonnet', {})
  }

  changePage(pageToRender) {
    if (pageToRender !== 'collagePage') {
      this.props.storePayload({
        isTooltip: false
      })
    }
    const pages = Object.keys(this.state.pages)
    const newState = {}
    pages.forEach((page) => {
      if (page === pageToRender) {
        newState[page] = true
      } else {
        newState[page] = false
      }
    })
    this.setState({...this.state, pages: {...newState}} )
  }

  setAppState(newState) {
    this.setState({
      ...this.state,
      ...newState,
    })
  }

  togglePage(pageToToggle) {
    this.setState({
      ...this.state,
      pages: {
        ...this.state.pages, [pageToToggle]: !this.state.pages[pageToToggle],
      },
    })
  }

  componentDidMount() {
    console.log('1.11.21.34')
    this.props.createFirebaseApp()
    // Resizing functionality
    let height = window.innerHeight
    let width = window.innerWidth
    this.setState({height, width})
    const resize = () => {
      if (window.orientation === 90 || window.orientation === -90) {
        height = window.innerWidth
        width = window.innerHeight
      } else {
        height = window.innerHeight
        width = window.innerWidth
      }

      this.setState({height, width})
      document.getElementById('root').style.width = width
      document.getElementById('root').style.height = height
    }
    window.addEventListener('resize', resize)
    window.addEventListener('orientationchange', () => {
      this.setState({
        orientationCounter: this.state.orientationCounter + 1,
      }) // trigger new render
      resize()
    })
    resize()
  }

  UNSAFE_componentWillReceiveProps(props) {
    if (props.authStatus && props.authStatus === 'failure') {
      props.cookies.remove('email', {path: '/'})
      props.cookies.remove('password', {path: '/'})
    }
    if (
        props.patientData.data &&
        Object.keys(props.patientData.data).length &&
        !props.patientData.data.Sub &&
        props.subscriptionData &&
        (props.subscriptionData.subPlan === 'None' || props.subscriptionData.subPlan === 'error')
      ) {
      this.changePage('onboardPage')
    } else if (props.isSignedIn && this.state.pages.authPage) {
      this.changePage('collagePage')
    }
    if (props.userMessage === 'Your subscription has been updated') {
      // console.log('replacing href')
      this.changePage('collagePage')
    }
    // if (props.database) { // sign in with cookies
    //   const isDatabaseReady =
    //     props.database && Object.keys(props.database).length > 0
    //   if (props.cookies && isDatabaseReady) {
    //     console.log('cookies and database ready')
    //     const email = props.cookies.get('email')
    //     const password = props.cookies.get('password')
    //     if (email && password) {
    //       console.log('signing in with cookies')
    //       props.signIn(email, password, props.cookies, props.firestore)
    //     }
    //   }
    // }
    if (props.notificationType === 2) {
      setTimeout(this.onClickNotification.bind(this), 8000)
    }
  }

  /* Redirects depending on signedIn status */
  routeAccordingToAuth(path, component, isExact) {
    const {isSignedIn} = this.props
    return this.state.isLoading ? (
      <Route
        path={path}
        component={FullLoadingScreen}
      />
    ) : (
      isSignedIn ? (
        isExact ? ( // signed in exact path
          <Route
            exact path={path}
            render={(props) => (
              <WithNav {...props} component={component} />
            )}
          />
        ) : ( // signed in, not exact path
          <Route
            path={path}
            render={(props) => (
              <WithNav
                {...props} pages={this.state.pages}
                changePage={this.changePage.bind(this)}
                component={component}
              />
            )}
          />
        )
      ) : ( // not signed in
        isExact ? (
          <Route exact path={path} component={Auth} />
        ) : (
          <Route path={path} component={Auth} />
        )
      )
    )
  }

  onClickNotification(event) {
    this.setState({
      isNotificationVisible: false,
    })
    setTimeout(() => {
      this.props.storePayload({
        userMessage: null,
        notificationType: null,
        notificationIcon: null,
      })
      this.setState({
        isNotificationVisible: true,
      })
    }, 300)
  }

  render() {
    /*  Single page app from M-Proc
        only renders at '/'
    */
    const spa = (
      <Fragment>
        {!this.props.isSignedIn ? (
          <Auth
            changePage={this.changePage.bind(this)}
          /> ) : (
          <Fragment>
            {this.state.pages.settingsPage &&
              <Settings
                changePage={this.changePage.bind(this)}
              />
            }
            {/* {this.state.pages.linkPhysician &&
              <LinkPhysician
                changePage={this.changePage.bind(this)}
              />
            } */}
            {this.state.pages.onboardPage &&
              <Onboard
                changePage={this.changePage.bind(this)}
              />
            }
            {this.state.pages.choosePlanPage &&
              <ChoosePlan
                changePage={this.changePage.bind(this)}
              />
            }
            {this.state.pages.imageUploadPage &&
              <ImageUpload
                changePage={this.changePage.bind(this)}
              />
            }
            {this.state.pages.bodyPartPage &&
              <BodyPart
                changePage={this.changePage.bind(this)}
              />
            }
            {this.state.pages.collagePage &&
              <WithNav
                pages={this.state.pages}
                changePage={this.changePage.bind(this)}
                component={Collage}
              />
            }
            {this.state.pages.testSetPage &&
              <TestSet
                changePage={this.changePage.bind(this)}
                pages={this.state.pages}
              />
            }
          </Fragment>
         )}
      </Fragment>
    )
    document.getElementById('root').style.minHeight = `${this.state.height}px`
    return (
      <BrowserRouter>
        <div
          className="App"
          id="App"
          style={{
            width: `${this.state.width}px`, height: `${this.state.height}px`,
          }}
        >
          {this.props.userMessage &&
            <Notification
              type={this.props.notificationType}
              image={this.props.notificationIcon === 'check' ? Check : Warning}
              message={this.props.userMessage}
              time={helpers.timeStampToTime(Date.now())}
              isVisible={this.state.isNotificationVisible}
              onClick={this.onClickNotification.bind(this)}
            />
          }
          {this.props.isSettings &&
          // {this.props.isSettings && // comment out when ready for sub check
            <WithNav
              changePage={this.changePage.bind(this)}
              component={Settings}
            />
          }
          {this.state.pages.linkPhysician &&
            // <WithNav
            //   changePage={this.changePage.bind(this)}
            //   component={LinkPhysician}
            // />
            <LinkPhysician changePage={this.changePage.bind(this)} />
          }
          {this.props.isLoading && <FullLoadingScreen />}
          {this.routeAccordingToAuth('/:testId', Test, false )}
          <Route
            exact path='/'
            render={() => (
              spa
            )}
          />
        </div>
      </BrowserRouter>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  storage: state.firebaseReducer.storage,
  database: state.firebaseReducer.database,
  firestore: state.firebaseReducer.firestore,
  patientData: state.imageReducer.patientData,
  isSignedIn: state.imageReducer.isSignedIn,
  isLoading: state.imageReducer.isLoading,
  isSettings: state.imageReducer.isSettings,
  subscriptionData: state.imageReducer.subscriptionData,
  userMessage: state.imageReducer.userMessage,
  notificationIcon: state.imageReducer.notificationIcon,
  notificationType: state.imageReducer.notificationType,
  authStatus: state.imageReducer.authStatus,
  cookies: ownProps.cookies,
})

export default withCookies(connect(
  mapStateToProps,
  { // Actions
    createFirebaseApp,
    storePatientData,
    storeLocalStorageTestSets,
    storePayload,
    signIn,
  }
)(App))

