import React from 'react'
import styles from '../styles/timeline.css'

/*
  props: {
    steps: [null, null, null] // Array of anything to map,
    stepIndex: int // >= 0 && < steps.length
  }
*/

function Timeline(props) {
  const shortenedArray = props.steps.slice(0, props.steps.length - 1)
  const width = (1 / shortenedArray.length) * 100
  return (
    <div className={styles.timeline}>
      {shortenedArray.map((step, i) => {
        if (i === 0) {
          // first line
          const isFilled = props.stepIndex > 0
          const isCurrentStep = props.stepIndex === i + 1
          return (
            <div
              key={i}
              style={{
                width: `${width}%`
              }}
              className={styles.empty}
            >
              <div
                className={
                  isFilled ? (
                    isCurrentStep ?
                      styles.filledAnim :
                      styles.filled
                    ) : null
                }
              >
                <div className={`${styles.square} ${styles.filledUnit} ${styles.firstUnit}`} />
                <div className={`${styles.circle} ${isFilled ? styles.filledUnit : styles.emptyUnit}`} />
              </div>
            </div>
          )
        } else if (i === props.steps.length - 2) {
          // last visible line
          const isFilled = props.stepIndex === props.steps.length - 1
          const isCurrentStep = props.stepIndex === i + 1
          return (
            <div
              key={i}
              className={styles.empty}
              style={{
                width: `${width}%`
              }}
            >
              <div
                className={
                  isFilled ? (
                    isCurrentStep ?
                      styles.filledAnim :
                      styles.filled
                    ) : null
                }
              >
                <div className={`${styles.square} ${isFilled ? styles.filledUnit : styles.emptyUnit}`} />
              </div>
            </div>
          )
        } else if (i === props.steps.length - 1) {
          // empty line
          return (
            <div key={i} style={{width: 0}} />
          )
        } else {
          // middle line
          const isFilled = props.stepIndex > i
          const isCurrentStep = props.stepIndex === i + 1
          return (
            <div
              key={i}
              className={styles.empty}
              style={{
                width: `${width}%`
              }}
            >
              <div
                className={
                  isFilled ? (
                    isCurrentStep ?
                      styles.filledAnim :
                      styles.filled
                    ) : null
                }
              >
                <div
                  className={`${styles.circle} ${isFilled ? styles.filledUnit : styles.emptyUnit}`}
                />
              </div>
            </div>
          )
        }
      })}
    </div>
  )
}

export default Timeline
