// Dependencies
import React, {useEffect} from 'react'
import firebase, { analytics } from 'firebase/app'
import dm from '../Images/dm48.svg'
import StripeCheckout from 'react-stripe-checkout'
import {connect} from 'react-redux'

// Actions
import {choosePlan} from '../actions/choosePlan'

// Components
import {
  Button,
} from '@anishp16/lazarus-cds'

// Styles
import '@anishp16/lazarus-cds/dist/index.css'
import '../styles/Onboard.css'

// Images
import leftArrow from '../Images/LeftArrow.svg'
import rightArrow from '../Images/RightArrow.svg'
import backArrow from '../Images/BackArrow.svg'

/*
  ChoosePlan is the user's first opportunity to select a plan
  after Onboard component. Subsequent changes to plan are made in Settings
*/

function ChoosePlanComp(props) {
  useEffect(() => {
    if (props.userMessage === 'Your subscription has been updated') {
      props.changePage('collagePage')
    }
  }, [props.userMessage])

  const onClickStart = (token, plan) => {
    const mixpanel = require('mixpanel-browser')
    mixpanel.track('Click Choose Plan', {user: firebase.auth().currentUser.uid})
    const data = {
      ...props.subscriptionData,
      name: props.patientData.data.Info.Name,
      email: props.patientData.data.Info.ContactEmail,
      patient_id: firebase.auth().currentUser.uid,
    }
    props.choosePlan(props.firestore, token, plan, data)
  }

  return (
    <div className='onboard-page'>
      <header>
        <img src={dm} alt='dm' />
        <p>Clinical Decision Support</p>
        <p className='bold'>Dermatology</p>
      </header>
      <div>
        <p>Choose a plan</p>
      </div>
      <div>
        <p>
          Features:
        </p>
        <p>
          - Upload and track skin conditions
        </p>
        <p>
          - Automatically sync with physician dashboard
        </p>
        <p>
          - Attach notes
        </p>
        <div className='plan-selection'>
        <StripeCheckout
          stripeKey={process.env.REACT_APP_STRIPE_KEY}
          token={(token) => onClickStart(token, 'patient-monthly')}
          email={props.patientData.data ? props.patientData.data.Contact.Email : null}
        >
          <div className='plan-card'>
            <div>
              <p>MONTHLY PLAN</p>
              <p>$5/month</p>
            </div>
            <img src={rightArrow} alt='arrow' />
          </div>
        </StripeCheckout>
        <StripeCheckout
          stripeKey={process.env.REACT_APP_STRIPE_KEY}
          token={(token) => onClickStart(token, 'patient-yearly')}
          email={props.patientData.data ? props.patientData.data.Contact.Email : null}
        >
          <div className='plan-card blue-card'>
            <div>
              <p>YEARLY PLAN</p>
              <p>$50/year</p>
            </div>
            <img src={rightArrow} alt='arrow' />
          </div>
        </StripeCheckout>
        </div>
      </div>
      <footer className='floating-back-btn' onClick={() => props.changePage('onboardPage')}>
        <img src={backArrow} alt='back' />
      </footer>
    </div>
  )
}

const mapStateToProps = state => ({
  storage: state.firebaseReducer.storage,
  database: state.firebaseReducer.database,
  firestore: state.firebaseReducer.firestore,
  patientData: state.imageReducer.patientData,
})

export default connect(mapStateToProps, {choosePlan})(ChoosePlanComp)
