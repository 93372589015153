import React from 'react'
import styles from '../styles/Input.css'

const Input = ({
  label = null,
  type = 'text',
  name,
  onChange,
  width = '100%',
  disabled = false,
  value = null, // for disabled forms
  style = {},
  min = 0,
  max = null,
  placeholder = null
}) => {
  return (
    <label
      className={styles.inputLabel}
      style={{
        width: width
      }}
    >
      {label}
      <input
        className={styles.input}
        type={type}
        name={name}
        onChange={onChange}
        disabled={disabled}
        value={value}
        style={style}
        placeholder={placeholder}
      />
    </label>
  )
}

export default Input
