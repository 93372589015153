import React, {useState, useEffect} from 'react'
import {connect} from 'react-redux'
import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/database'

// Actions
import {storePayload} from '../actions/storePayload'
import {getEncryptedLink} from '../actions/Auth/getEncryptedLink'

// Components
import {Input, Button} from '@anishp16/lazarus-cds'

// Images
import lazarus from '../Images/Lazarus.svg'
import xCircle from '../Images/xCircleBlack.svg'
import warning from '../Images/Warning.svg'

// Styles
import '@anishp16/lazarus-cds/dist/index.css'
import '../styles/LinkProvider.css'

function LinkProvider(props) {
  const [providerForm, setProviderForm] = useState({})
  const [isLinkCopied, setIsLinkCopied] = useState(false)
  const [isLinkGenerated, setIsLinkGenerated] = useState(false)
  const [isForm, setIsForm] = useState(true)
  const {data} = props.patientData

  useEffect(() => {
    if (props.patientData && props.patientData.data.Forms && props.patientData.data.Forms.Provider) {
      // props.storePayload({
      //   providerLink: 'Anish link here'
      // })
      props.getEncryptedLink('LinkingLink','')
      setIsLinkGenerated(true)
      setIsForm(false)
    }
  }, [])
  const onChangeProviderForm = (event) => {
    const newForm = providerForm
    newForm[event.target.name] = event.target.value
    setProviderForm(newForm)
  }
  const copyLinkToClipBoard = () => {
    // const input = document.createElement('input');
    backup()
    // document.body.appendChild(input);
    // input.value = props.providerLink;
    // input.style.opacity = 0
    // input.focus();
    // input.select();
    // let res
    // try {
    //   const result = document.execCommand('copy');
    //   res = result
    //   console.log('result', result)
    // } catch (err) {
    //   console.log('Unable to copy to clipboard', err)
    // }
    // if (res === 'successful') {
    //   setIsLinkCopied(true)
    // } else {
    //   console.error('Failed to copy text.')
    //   backup()
    // }
    // function backupCopyText() {
    //   navigator.permissions.query({name: 'clipboard-write'})
    //     .then((result) => {
    //       alert(result.state)
    //       if (result.state === 'granted' || result.state === 'prompt') {
    //         /* write to the clipboard now */
    //         navigator.clipboard.writeText(props.providerLink)
    //             .then(() => {
    //               /* clipboard successfully set */
    //               setIsLinkCopied(true)
    //               console.log('copied!')
    //             })
    //             .catch(() => {
    //               /* clipboard write failed */
    //               alert('clipboard write failed')
    //               console.log('clipboard write failed')
    //             })
    //       } else {
    //         const input = document.createElement('input');
    //         document.body.appendChild(input);
    //         input.value = props.providerLink;
    //         input.style.display = 'none'
    //         input.focus();
    //         input.select();
    //         const result = document.execCommand('copy');
    //         if (result === 'unsuccessful') {
    //           console.error('Failed to copy text.');
    //         } else {
    //           setIsLinkCopied(true)
    //         }
    //       }
    //     })
    //     .catch(() => alert('error'))
    // }
    function backup() {
        navigator.clipboard.writeText(props.providerLink).then(function() {
        console.log('Async: Copying to clipboard was successful!');
        setIsLinkCopied(true)
      }, function(err) {
        console.error('Async: Could not copy text: ', err);
      });
    }
  }

  return (
    <div className='full-screen-modal'>
      <div>
        <img className='lazarus-logo' src={lazarus} alt='Lazarus' />
        <div className='flex justify-end'>
          <img
            style={{cursor: 'pointer'}}
            onClick={
              () => {
                props.storePayload({isSettings: true})
                props.changePage('collagePage')
              }
            }
            src={xCircle} alt='cancel'
          />
        </div>
        <div
            className='settings-btn'
          >
            <p>
              {data && data.Info && data.Info.FirstName.slice(0, 1) + data.Info.LastName.slice(0, 1)}
            </p>
        </div>
        <p className='name'>{data && data.Info && data.Info.Name}</p>
        <p>{data && data.Contact && data.Contact.Email}</p>
      </div>
      {isForm &&
      <div className='provider-form'>
        <div>
          <Input
            label='Provider Name'
            onChange={onChangeProviderForm}
            name='name'
            style={{
              marginBottom: '0'
            }}
          />
          <p>(Optional)</p>
        </div>
        <Input
          label='Provider Location'
          onChange={onChangeProviderForm}
          name='location'
        />
        <div>
          <Input
            label='Provider Email'
            onChange={onChangeProviderForm}
            name='email'
            style={{
              marginBottom: '0'
            }}
          />
          <p>(Optional)</p>
        </div>
      </div>
      }
      {isLinkGenerated &&
        <div>
          <div className='provider-link-div'>
            <p className='provider-link'>{props.providerLink}</p>
          </div>
          <Button
            type={isLinkCopied ? 2 : 1}
            disabled={isLinkCopied}
            text={isLinkCopied ? 'Link Copied!' : 'Copy Link'}
            onClick={copyLinkToClipBoard}
          />
        </div>
      }
      {!isLinkGenerated &&
      <Button
        type={1}
        text='Generate Link'
        onClick={() => {
          if (providerForm.location) {
            props.getEncryptedLink('LinkingLink','')
            const defaultForms = props.patientData.data.Forms || {}
            props.firestore.
              collection('LCDS')
              .doc('Something')
              .collection('Patients')
              .doc(firebase.auth().currentUser.uid)
              .update({
                Forms: {
                  ...defaultForms,
                  Provider: {
                    ...providerForm,
                  }
                }
              })
              firebase.database().ref('Dermatology/Forms/' + firebase.auth().currentUser.uid + '').update({
                providerForm
              })
            setIsLinkGenerated(true)
          } else {
            props.storePayload({
              userMessage: 'Please include provider location',
              notificationType: 1,
              notificationIcon: 'warning',
            })
          }
        }}
      />
      }
    </div>
  )
}

const mapStateToProps = (state) => ({
  firestore: state.firebaseReducer.firestore,
  patientData: state.imageReducer.patientData,
  providerLink: state.imageReducer.LinkingLink,
})

export default connect(
    mapStateToProps, {storePayload, getEncryptedLink}
)(LinkProvider)