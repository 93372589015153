import firebase from 'firebase/app'
import 'firebase/auth'

export const getEncryptedLink = (keyToAssign, stringToEncrypt) => (dispatch) => {
  const getEncryptURL = process.env.REACT_APP_URL + 'sonnetEncrypt'

  const timestamp = Date.now()

  const myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");

  const raw = JSON.stringify(
    { 
      original: firebase.auth().currentUser.uid,
      timestamp: timestamp,
    },
  );
  const requestOptions = {
    method: 'POST',
    headers: myHeaders,
    body: raw,
    redirect: 'follow',
  };

  fetch(getEncryptURL, requestOptions)
    .then(response => {
      return response.json()
    })
    .then(res => {
      let patient = res.encrypt
      dispatch({
        type: 'STORE_PAYLOAD',
        payload: {
          [keyToAssign]: 'https://link.lazarusderm.com/?timestamp=' + timestamp + '&patient=' + encodeURIComponent(patient)
        },
      })
    })

  // var encryptedPlainText = aes256.encrypt(key, plaintext);
  // var decryptedPlainText = aes256.decrypt(key, encryptedPlainText);
  // // plaintext === decryptedPlainText

  // var encryptedBuffer = aes256.encrypt(key, buffer);
  // var decryptedBuffer = aes256.decrypt(key, encryptedBuffer);
  // plaintext === decryptedBuffer.toString('utf8)
}