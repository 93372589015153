export const createTest = (time, moleLocation, moleCoordinates, front) => dispatch => {
  dispatch({
    type: 'CREATE_TEST',
    payload: {
      time: time,
      moleCoordinates: moleCoordinates,
      moleLocation: moleLocation,
      front: front,
      croppedImageUrls: [],
      croppedImageFiles: [],
      croppedImageDataURLS: [],
    }
  })
}