import React from 'react'
import '../styles/Input.css'
import styles from '../styles/BirthdayForm.css'
import Input from './Input'

const BirthdayForm = ({
  onChangeDay,
  onChangeMonth,
  onChangeYear,
}) => {
  return (
    <div className={styles.birthdayForm}>
      <p>Date of Birth (mm/dd/yyyy)</p>
      <div className={styles.inputContainer}>
        <Input
          // label='Month'
          onChange={onChangeMonth}
          type='number'
          min={1}
          max={12}
          width='30%'
          name='month'
          placeholder='mm'
        />
        <Input
          // label='Day'
          onChange={onChangeDay}
          type='number'
          min={1}
          max={12}
          width='30%'
          name='day'
          placeholder='dd'
        />
        <Input
          // label='Year'
          onChange={onChangeYear}
          type='number'
          min={1900}
          width='30%'
          name='year'
          placeholder='yyyy'
        />
      </div>
    </div>
  )
}

export default BirthdayForm
