// Dependencies
import React, {Fragment, useEffect, useState} from 'react'
import {useHistory} from "react-router-dom";
import firebase from 'firebase/app'
import {Link, Redirect} from 'react-router-dom'
import {connect} from 'react-redux'
import helpers from '../helpers.js'

// Actions
import {storePayload} from '../actions/storePayload'
import {createTest} from '../actions/createTest'

// Components
import {
  Switch,
  Loader,
  Modal,
  Timeline,
} from '@anishp16/lazarus-cds'

// Images
import flagIcon from '../Images/flag.svg'
import notesImg from '../Images/notes.svg'
import backArrow from '../Images/BackArrow.svg'
import check from '../Images/check.svg'
import timeline from '../Images/Timeline.svg'

// Styles
import '../styles/Test.css'
import '@anishp16/lazarus-cds/dist/index.css'

function Test(props) {
  const history = useHistory()
  const notesRef = React.createRef()
  /* renders progress bar with physician actions */
  const [isReviewRequested, setIsReviewRequested] = useState(false)
  /* Physician review timeline step */
  const [stepIndex, setStepIndex] = useState(0)
  /* Allowed to request review again after 24h */
  const [isPingable, setIsPingable] = useState(false)
  const [isConfirmationModal, setIsConfirmationModal] = useState(false)
  const [isConfirmationModalShowing, setIsConfirmationModalShowing] = useState(true)
  const [isConfirmationModal2, setIsConfirmationModal2] = useState(false)
  const [isConfirmationModal2Showing, setIsConfirmationModal2Showing] = useState(true)
  const [flag, setFlag] = useState(false)
  const [timeOfType, setTimeOfType] = useState(null)
  const [isTyping, setIsTyping] = useState(false)
  const [notes, setNotes] = useState(null)
  const [lastTest, setLastTest] = useState(null)
  const [redirect, setRedirect] = useState(false)
  const [haveNotesBeenUpdated, setHaveNotesBeenUpdated] = useState(false)
  
  const app = document.getElementsByClassName('App')[0]
  const em = parseFloat(getComputedStyle(app).fontSize)
  const appWidth = app.offsetWidth
  const imgWidth = (appWidth - (2 * em)) * .75
  const step1Milliseconds = 10000
  const step2Milliseconds = 70000

  useEffect(() => {
    // Enable direct url
    if (!props.currentTest) {
      // setRedirect(true)
      const testId = props.match.params.testId
      if (testId) {
        const currentTestArr =
          props.patientData.tests.filter((test) => test.Id === testId)
          if (currentTestArr.length) {
            const newCurrentTest = currentTestArr[0]
            props.storePayload({
              currentTest: newCurrentTest,
              isLoading: false,
            })
          } else {
            setRedirect(true)
          }
      } else {
        setRedirect(true)
      }
    }
  }, [])

  useEffect(() => { // Set currentTest to updated currentTest (with new image)
    if (props.currentTest) {
      setLastTest(props.currentTest.Tests[props.currentTest.Tests.length - 1])
      if (props.currentTest.Flag) {
        setFlag(props.currentTest.Flag)
      }
    // Set is pingable
      if (props.currentTest.PingTime) {
        setIsReviewRequested(true)
        const now = Date.now()
        const elapsedTime = now - props.currentTest.PingTime
        const isReviewed = props.currentTest.LastReview ?
          props.currentTest.LastReview.Time > props.currentTest.PingTime : false
        const rePingTime = 86400000 // 24 hours
        if (isReviewed) {
          setStepIndex(3)
          setIsPingable(true)
        }
        else if (elapsedTime >= rePingTime) {
          setIsPingable(true)
        } else if (elapsedTime >= step2Milliseconds) {
          setStepIndex(2)
        } else if (elapsedTime >= step1Milliseconds) {
          setStepIndex(1)
        }
      } else {
        setIsPingable(true)
      }
    }
  }, [props.currentTest])

  useEffect(() => {
    const testId = props.match.params.testId
    const newCurrentTest = props.patientData.tests.filter((iTest) => iTest.Id === testId)[0]
    props.storePayload({
      currentTest: newCurrentTest
    })
  }, [props.patientData])

  useEffect(() => {
    if (!haveNotesBeenUpdated && notesRef.current && props.currentTest.Notes && !notesRef.current.value) {
      notesRef.current.value = props.currentTest.Notes
      setHaveNotesBeenUpdated(true)
    }
  }, [notesRef])

  const onChangeFlag = () => {
    setFlag(!flag)
    updateTest('Flag', !flag)
  }

  /* Adds timestamp to patient doc indicating when review-request was sent */
  const updatePingTime = () => {
    const pingTime = Date.now()
    props.firestore.
        collection('LCDS')
        .doc('Something')
        .collection('Patients')
        .doc(firebase.auth().currentUser.uid)
        .collection('DermatologyTests')
        .doc(props.currentTest.Id)
        .update({
          PingTime: pingTime,
        })
        .then(() => {
          props.storePayload({isLoading: false})
          setIsConfirmationModal(false)
          setTimeout(() => {
            setStepIndex(1)
          }, step1Milliseconds)
          setTimeout(() => {
            setStepIndex(2)
          }, step2Milliseconds)
        })
  }

  const sendReviewRequest = () => {
    const orgId = props.patientData.data.Linked.Main
    const physicianId = props.patientData.data.Linked[orgId]
    if (physicianId) {
      props.storePayload({
        isLoading: true,
      })
      const orgId = props.patientData.data.Linked ? props.patientData.data.Linked.Main : null
      const body = {
        patientId: firebase.auth().currentUser.uid,
        patientName: props.patientData.data.Info.Name,
        urlLink: props.providerLink,
        testId: props.currentTest.Id,
        physicianId: physicianId,
        orgId: orgId,
      }
      // console.log('body', body)
      helpers.postData(process.env.REACT_APP_URL + '/sonnetPingPhysician', body)
          .then((res) => {
            updatePingTime()
            setIsReviewRequested(true)
            setIsPingable(false)
            setStepIndex(0)
            updateTest('LastUpdated', Date.now())
          })
          .catch((error) => {
            console.log('error', error)
            handleMissingProvider()
          })
    } else {
      handleMissingProvider()
    }
  }

  /* Display warning message if !physicianId */
  function handleMissingProvider() {
    props.storePayload({
      isLoading: false,
      notificationType: 3,
      notificationIcon: 'warning',
      userMessage: 'There was an error reaching your provider. Please try again and contact us if the problem persists.'
    })
  }

  /* Update Flag/Notes values and update props.patientsData */
  function updateTest(key, value) {
    props.firestore.
        collection('LCDS')
        .doc('Something')
        .collection('Patients')
        .doc(firebase.auth().currentUser.uid)
        .collection('DermatologyTests')
        .doc(props.currentTest.Id)
        .update({
          [key]: value,
        })
        .catch((error) => console.log('error', error))
    /* Update test in front end */
    const newPatientData = props.patientData
    let testIndex
    let newCurrentTest
    /* Find index of test (indexOf(test) didn't work) */
    newPatientData.tests.forEach((patientTest, i) => {
      if (patientTest.Id === props.currentTest.Id) {
        testIndex = i
      }
    })
    newPatientData.tests[testIndex][key] = value
    newCurrentTest = newPatientData.tests[testIndex]
    props.storePayload({patientData: newPatientData, currentTest: newCurrentTest})
  }

  /*
    Submit notes after user stops typing for set amount of time (pauseLength)
  */
  useEffect((pauseLength = 2000) => {
    const timer = setTimeout(() => {
      const now = Date.now()
      if (isTyping && (now - timeOfType) >= pauseLength) {
        // console.log('is typing?', isTyping)
        setIsTyping(false)
        updateTest('Notes', notes)
        // console.log('is typing after cb?', isTyping)
      }
    }, pauseLength)
    return () => clearTimeout(timer)
  }, [timeOfType])

  const onChangeNotes = async (event) => {
    await setNotes(event.target.value)
    setIsTyping(true)
    setTimeOfType(Date.now())
  }

  const stepText = (step) => {
    switch (step) {
      case 0:
        return 'Sending review request'
      case 1:
        return 'Review request received'
      case 2:
        return 'Review in progress'
      case 3:
        return 'Outreach pending'
    }
  }

  return (
    <Fragment>
      {redirect && <Redirect to='/' />}
      <div className='test-page'>
        {isConfirmationModal &&
          <Modal
            style={{maxWidth: '100%'}}
            isVisible={isConfirmationModalShowing}
            time={helpers.timeStampToTime(Date.now())}
            message='Requesting a review from your physician may trigger a billable event. Ask your provider about their policy regarding Lazarus Patient Portal review requests.
            For medical emergencies, contact your physician directly. 
             Are you sure you want to continue?'
            cancelationText='No'
            cancelationFunc={() => {
              setIsConfirmationModalShowing(false)
              // setTimeout(() => {
              //   setIsConfirmationModal(false)
              // }, 1000)
            }}
            confirmationText='Yes'
            confirmationFunc={() => {
              setIsConfirmationModalShowing(false)
              sendReviewRequest()
              // setIsConfirmationModal2(true)
              // setIsConfirmationModal2Showing(true)
            }}
            image={check}
          />
        }
        {/* {isConfirmationModal2 &&
          <Modal
            isVisible={isConfirmationModal2Showing}
            time={helpers.timeStampToTime(Date.now())}
            message='Are you super duper sure?'
            cancelationText='No'
            cancelationFunc={() => {
              setIsConfirmationModal2Showing(false)
              // setTimeout(() => {
              //   setIsConfirmationModal(false)
              // }, 1000)
            }}
            confirmationText='Yes'
            confirmationFunc={() => {
              setIsConfirmationModal2Showing(false)
              sendReviewRequest()
            }}
            image={check}
          />
        } */}
        {props.currentTest && lastTest &&
          <div>
            <div className='test-page-location-container'>
              <div className='flex justify-space-between'>
                <p className='font-small'>{'Timeline Id: ' + props.currentTest.Id.slice(0, 8) + '...'}</p>
                <img src={timeline} alt='timeline' />
              </div>
              <p className='mole-location'>{props.currentTest.MoleLocation}</p>
              <div className='flex justify-space-between'>
                <p className='bold font-small'>
                  {
                    props.currentTest.Tests.length === 1 ?
                    props.currentTest.Tests.length + ' IMAGE' :
                    props.currentTest.Tests.length + ' IMAGES'
                  }
                </p>
                <div className='flex icon-div'>
                  {props.currentTest.Flag && 
                    <div className='icon-box'>
                      <img src={flagIcon} alt='flag' />
                    </div>
                  }
                  {props.currentTest.Notes && 
                    <div className='icon-box'>
                      <img src={notesImg} alt='notes' />
                    </div>
                  }
                </div>
              </div>
            </div>
            {isReviewRequested && !props.currentTest.isReviewed &&
              <div className='timeline-container'>
                <Timeline steps={[null, null, null, null]} stepIndex={stepIndex} />
                <p>{stepText(stepIndex)}</p>
              </div>
            }
            {props.currentTest && props.currentTest.Tests.length > 1 &&
            <div className='carousel-1'>
              <div>
                {props.currentTest.Tests.map((iTest, i) => {
                  if (i === props.currentTest.Tests.length - 1) {
                    return null
                  }
                  return (
                    <div
                      key={i}
                      className='mole-image-container'
                    >
                      <img style={{height: imgWidth}} src={iTest.ImageRef} />
                    </div>
                  )
                })}
              </div>
              <div className='label-container'>
                {props.currentTest.Tests.map((iTest, i) => {
                  if (i === props.currentTest.Tests.length - 1) {
                    return null
                  }
                  return (
                    <div
                      key={i}
                      className='mole-image-label'
                    >
                      <p>
                        {i === 0 ? 'First image' :
                          i === (props.currentTest.Tests.length - 1) ?
                            'Latest image' :
                            'Image taken on'
                        }
                      </p>
                      <p>
                        {helpers.timeStampToDate(iTest.DateTaken)}
                      </p>
                    </div>
                  )
                })}
              </div>
            </div>
            }
            <div className='carousel-2'>
              <div>
                <div
                  className='mole-image-container'
                >
                  <img style={{height: imgWidth}} src={lastTest.ImageRef} />
                </div>
                  <div
                    className='test-page-upload-btn'
                    onClick={() => {
                      props.createTest(
                          Date.now(),
                          props.currentTest.MoleLocation,
                          props.currentTest.MoleLocation,
                          props.currentTest.Front
                      )
                      props.changePage('testSetPage')
                    }}
                  >
                    <Link to='/'>+</Link>
                  </div>
              </div>
              <div className='label-container'>
                <div
                  className='mole-image-label'
                >
                  <p>
                    Latest Image
                  </p>
                  <p>
                    {helpers.timeStampToDate(lastTest.DateTaken)}
                  </p>
                </div>
                  <div className='upload-new-image-label'>
                    <p>Upload New Image</p>
                  </div>
              </div>
            </div>
            <div className={flag ? 'selected-tab filter-div' : 'filter-div'}>
              <div className='flex title'>
                <span>Condition</span>
                <img src={flagIcon} alt='flag' />
              </div>
              <div className='flex'>
                <span className='flag'>Flag</span>
                <Switch
                  checked={flag}
                  onChange={onChangeFlag}
                />
              </div>
            </div>
            <div className='notes-container'>
              <div className='flex space-between'>
                <span>Notes</span>
                <img src={notesImg} alt='notes' />
              </div>
              <div style={{position: 'relative'}}>
                <textarea ref={notesRef} onChange={onChangeNotes} />
                {isTyping &&
                  <Loader
                    style={
                      {
                        right: 0,
                        bottom: 0,
                        height: '3em',
                        width: '3em'
                      }
                    }
                  />
                }
              </div>
            </div>
          </div>
        }
        <Link
          to='/'
          onClick={() => {
            props.changePage('collagePage')
          }}
        >
        <footer className='floating-back-btn'>
          <img src={backArrow} alt='back' />
        </footer>
        </Link>
        {props.patientData.data.Linked &&
          <div
            className={isPingable ? 'request-review-btn' : 'request-review-btn disabled'}
            onClick={() => {
              if (isPingable) {
                setIsConfirmationModal(true)
                setIsConfirmationModalShowing(true)
              } else {
                props.storePayload({
                  userMessage: 'Please wait at least 24 hours before requesting another review.',
                  notificationIcon: 'warning',
                  notificationType: 3,
                })
              }
            }}
          >
            <pre>Request review</pre>
          </div>
        }
      </div>
    </Fragment>
  )
}

const mapStateToProps = (state) => ({
  storage: state.firebaseReducer.storage,
  database: state.firebaseReducer.database,
  firestore: state.firebaseReducer.firestore,
  patientData: state.imageReducer.patientData,
  currentTest: state.imageReducer.currentTest,
  providerLink: state.imageReducer.providerLink,
})

export default connect(
    mapStateToProps,
    {storePayload, createTest},
)(Test)