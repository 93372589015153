import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/database';
import 'firebase/firestore'
import 'firebase/storage'
import 'firebase/analytics'
import firebaseConfig from '../firebaseConfig'

export const createFirebaseApp = () => (dispatch) => {
  const firebaseApp = !firebase.apps.length ?
  firebase.initializeApp(firebaseConfig) :
  firebase.app()
  firebase.analytics()
  const firebaseAppPayload = {
    database: firebaseApp.database(),
    firestore: firebaseApp.firestore(),
    storage: firebaseApp.storage(),
  }

  dispatch({
    type: 'CREATE_FIREBASE_APP',
    payload: firebaseAppPayload
  })
}