import React, { Component, Fragment } from 'react';
import { CardElement } from '@stripe/react-stripe-js';
import { connect } from 'react-redux'
import { storePaymentIntent } from '../actions/storePaymentIntentAction'
import LoadingIcon2 from './LoadingIcon2'

import CardSection from './CardSection';

class CheckoutForm extends Component {
  constructor(props) {
    super(props)
    this.state = {
      hasStarted: false
    }
  }

  componentDidMount() {
    this.setState({ secret: this.props.secret, error: null })
  }

  handleSubmit = async () => {
    // We don't want to let default form submission happen here,
    // which would refresh the page.
    // event.preventDefault();

    if (!this.state.hasStarted) {
      this.setState({
        hasStarted: true,
        error: null
      })
      const { stripe, elements } = this.props
  
      if (!stripe || !elements) {
        // Stripe.js has not yet loaded.
        // Make  sure to disable form submission until Stripe.js has loaded.
        return;
      }
      const { secret } = this.state
      const result = await stripe.confirmCardPayment(secret, {
        payment_method: {
          card: elements.getElement(CardElement),
        }
      })
  
      if (result.error) {
        // Show error to your customer (e.g., insufficient funds)
        console.log(result.error.message);
        this.setState({
          hasStarted: false,
          error: result.error.message
        })
      } else {
        // The payment has been processed!
        if (result.paymentIntent.status === 'succeeded') {
          this.props.storePaymentIntent(result.paymentIntent)
          this.setState({ hasStarted: false })
          this.props.changePage('loadingScreenPage')
          
          // Show a success message to your customer
          // There's a risk of the customer closing the window before callback
          // execution. Set up a webhook or plugin to listen for the
          // payment_intent.succeeded event that handles any business critical
          // post-payment actions.
        }
      }
    }
  }

  render() {
    return (
      <Fragment>
        <form className="stripe-form" onSubmit={this.handleSubmit}>
          <CardSection />
          {this.state.error &&
            <p style={{ color: 'red' }}>{this.state.error}</p>
          }
          {this.props.stripe && !this.state.hasStarted &&
          <div
            className="btn-div vertical-btn"
            // disabled={!this.props.stripe || this.state.hasStarted}
            style={{ margin: '0 0 1em' }}
            onClick={this.handleSubmit}
          >
            <p>Confirm order</p>
          </div>
          }
          {this.state.hasStarted &&
            <LoadingIcon2 />
          }
        </form>
      </Fragment>
    )
  }
}

const mapStateToProps = state => ({
  secret: state.imageReducer.secret
})

export default connect(mapStateToProps, {storePaymentIntent})(CheckoutForm)