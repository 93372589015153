
export default function(
    state = {
      testSets: [],
      testSetIndex: 0,
      imgIndex: 0,
      images: {
        croppedImageUrls: [],
        croppedImageFiles: [],
        croppedImageDataURLS: [],
      },
      indexOfImageToReplace: null,
      skipPayment: true,
      paymentIntent: 'n/a',
      patientData: {},
      subscriptionData: {},
      isSettings: false, // Toggles settings page
      isTooltip: false,
      newTestId: null,
      /* Link for physician to gain access to patient */
      providerLink: '',
      LinkingLink: '',
    },
    action) {
  switch (action.type) {
    case 'STORE_PAYLOAD':
      return {
        ...state,
        ...action.payload,
      }
    case 'CREATE_TEST':
      // const testSetIndex = state.testSetIndex + 1
      // window.localStorage.setItem('storageState', JSON.stringify({
      //   ...state,
      //   testSets: [...state.testSets, action.payload]
      // }))
      return {
        ...state,
        test: action.payload,
        isEditing: false,
        newTestId: null,
        // testSetIndex: testSetIndex
      }
    case 'DELETE_TESTSET':
      const testSets = state.testSets
      const testSetIndexToRemove = action.payload
      const testSets1 = testSets.slice(0, testSetIndexToRemove)
      let testSets2 = []
      if (testSets[testSetIndexToRemove + 1]) {
        testSets2 = testSets.slice(testSetIndexToRemove + 1, testSets.length)
      }
      const mergedTestSets = testSets1.concat(testSets2)
      // try {
      //   window.localStorage.setItem('storageState', JSON.stringify({
      //     ...state,
      //     testSets: mergedTestSets,
      //   }))
      // }
      // catch(event) {
      //   // console.log('Local storage is full', event)
      // }
      return {
        ...state,
        testSets: mergedTestSets,
      }
    case 'STORE_CROPPED_IMAGE':
      const newTest = {
        ...state.test,
        image: action.payload,
      }
      return {
        ...state,
        test: newTest,
      }
    case 'STORE_NOTES':
      return {
        ...state,
        notes: action.payload,
      }
    case 'CLEAR_IMAGES':
      return {
        ...state,
        images: {
          croppedImageUrls: [
            ...state.images.croppedImageUrls,
            action.payload.croppedImageUrls,
          ],
          croppedImageFiles: [
            ...state.images.croppedImageFiles,
            action.payload.croppedImageFiles,
          ],
        },
      }
    case 'REMOVE_IMAGE': {
      const newCroppedImageUrls1 = state.images.croppedImageUrls.slice(0, action.payload)
      const newCroppedImageUrls2 = state.images.croppedImageUrls.slice(action.payload + 1, state.images.croppedImageUrls.length)
      const newCroppedImageUrls = newCroppedImageUrls1.concat(newCroppedImageUrls2)

      const newCroppedImageFiles1 = state.images.croppedImageFiles.slice(0, action.payload)
      const newCroppedImageFiles2 = state.images.croppedImageFiles.slice(action.payload + 1, state.images.croppedImageFiles.length)
      const newCroppedImageFiles = newCroppedImageFiles1.concat(newCroppedImageFiles2)
      return {
        ...state,
        images: {
          croppedImageUrls: newCroppedImageUrls,
          croppedImageFiles: newCroppedImageFiles
        }
      }
    }
    case 'SPLICE_IMAGE': {
      const newCroppedImageUrls = state.images.croppedImageUrls
      const newCroppedImageFiles = state.images.croppedImageFiles
      newCroppedImageUrls.splice(action.payload.index, 1, action.payload.croppedImageUrl)
      newCroppedImageFiles.splice(action.payload.index, 1, action.payload.croppedImageFile)
      return {
        ...state,
        images: {
          croppedImageUrls: newCroppedImageUrls,
          croppedImageFiles: newCroppedImageFiles
        }
      }
    }
    case 'STORE_IMAGE_INDEX': {
      return {
        ...state,
        indexOfImageToReplace: action.payload
      }
    }
    case 'STORE_TESTSET_AND_IMAGE_INDEX': {
      // window.localStorage.setItem('storageState', JSON.stringify({
      //   ...state,
      //   testSetIndex: action.payload.testSetIndex,
      //   imgIndex: action.payload.imgIndex
      // }))
      return {
        ...state,
        testSetIndex: action.payload.testSetIndex,
        imgIndex: action.payload.imgIndex,
      }
    }
    case 'STORE_ORIGINAL_IMAGE': {
      return {
        ...state,
        originalImage: action.payload,
      }
    }
    case 'STORE_LOCAL_STORAGE_TEST_SETS': {
      return {
        ...state,
        testSets: action.payload,
      }
    }
    case 'STORE_SECRET': {
      return {
        ...state,
        secret: action.payload,
      }
    }

    case 'SUBMIT_TEST_SETS': {
      return {
        ...state,
        batchUploadResponse: action.payload,
      }
    }

    case 'STORE_PATIENT_DATA': {
      return {
        ...state,
        patientData: action.payload.patientData,
        patientName: action.payload.name,
        patientID: action.payload.id,
      }
    }

    case 'STORE_PAYMENT_INTENT': {
      return {
        ...state,
        paymentIntent: action.payload,
      }
    }

    case 'STORE_ISEDITING': {
      return {
        ...state,
        isEditing: action.payload,
      }
    }

    case 'STORE_SKIP_PAYMENT': {
      return {
        ...state,
        skipPayment: action.payload,
      }
    }

    default:
      return state
  }
}
