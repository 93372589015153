import React, {Component} from 'react'
import {Redirect} from 'react-router-dom'
import firebase from 'firebase/app'
import {connect} from 'react-redux'
// import { removeImage } from './removeImageAction'
import Deck from './Deck'
import xCircleBlack from '../Images/xCircleBlack.svg'
import {storeTestSetAndImgIndex} from '../actions/storeTestSetAndImgIndexAction'
import {storeNotes} from '../actions/storeNotesAction'
import {deleteTestSet} from '../actions/deleteTestSetAction'
import {submitTest} from '../actions/submitTest'
import {storePayload} from '../actions/storePayload'
import Upload from './Upload'
import helpers from '../helpers'

class TestSet extends Component {
  constructor(props) {
    super(props);
    this.myRef = React.createRef()
    this.myRef2 = React.createRef()
    this.myRef3 = React.createRef()
    this.state = {
      testSetIndex: 0,
      batchId: null,
      modal: false,
      redirect: false,
    }
  }

  scroll(ref) {
    const self = this
    function smoothScrolling(e, time, amount, start, direction) {
      var eAmt = amount / 100;
      var curTime = 0;
      var scrollCounter = 0;
      while (curTime <= time) {
          window.setTimeout(incrementalScroll, curTime, e, scrollCounter, eAmt, start, direction);
          curTime += time / 100;
          scrollCounter++;
      }
    }
    
    function incrementalScroll(e, sc, eAmt, start, direction) {
        if (direction === 'vertical' && !self.state.isEditing) {
          const VertAmount = (eAmt * sc) + start;
          window.scrollTo({
            top: VertAmount
          })
        } else {
          e.scrollLeft = (eAmt * sc) + start;
        }
    }

    const fontSize = parseFloat(getComputedStyle(ref.current).fontSize)
    const scrollWidth = ref.current.scrollWidth - (fontSize * 2)
    const imageCount = 1
    const scrollAmount = imageCount / 3 * scrollWidth
    let initialScrollAmount = 0

    this.myRef3.current.scrollTop = 0 // Start at top of page

    if (imageCount > 1) {
      initialScrollAmount = (imageCount - 1) / 3 * scrollWidth
      ref.current.scrollTo({top: 0, left: initialScrollAmount}) // Start from latest carousel position
    }
    if (imageCount === 3) { // For shorter screens, scroll to show 'done' button
      setTimeout(() => {
        // this.scrollIntoView() // Not working on mobile
        smoothScrolling(this.myRef3.current, 500, this.myRef3.current.scrollHeight, 0, 'vertical')
      }, 500)
    } else { // Scroll through carousel
      setTimeout(() => {
        ref.current.scrollTo({top: 0, left: scrollAmount, behavior: 'smooth'})
        smoothScrolling(ref.current, 275, scrollAmount, initialScrollAmount, 'horizontal')
      }, 500)
    }
  }

  componentDidMount() {
    // this.scroll(this.myRef)
    if (this.props.isEditing) {
      this.setState({isEditing: true})
    }
  }

  UNSAFE_componentWillReceiveProps(props) {
    if (props.newTestId) {
      this.setState({redirect: true})
    }
  }

  handleDate() {
    const date = new Date(this.props.test.time)
    const hour = date.getHours().toString()
    const minutes = date.getMinutes().toString()
    const add0 = (str) => {
      if (str.length === 1) {
        return '0' + str
      } return str
    }
    const time = add0(hour) + ':' + add0(minutes)
    return time
  }

  handleNotes = (event) => {
    this.setState({ notes: event.target.value })
    this.props.storeNotes(event.target.value)
  }

  render() {
    const root = document.getElementById('root')
    const fontSize = parseFloat(getComputedStyle(root).fontSize)
    const contentWidth = document.getElementsByClassName('App')[0] ?
      document.getElementsByClassName('App')[0].offsetWidth : 0
    const testSet = this.props.test
    const uploadContainerStyle = { height: `${contentWidth - (fontSize * 2)}px`, width: `${contentWidth - (fontSize * 2)}px`}
    return (
      <div
        id="testSet-container"
        className="testSet-container"
        style={{ minHeight: `${window.innerHeight}px` }}
        ref={this.myRef3}
      >
        {this.state.redirect &&
          <Redirect to={`/${this.props.newTestId}`} />
        }
        {this.state.modal &&
          <div
            className={
              this.state.slideUp ? "full-modal slideInUp" : "full-modal slideOutDown"
            }
            style={{ width: `${contentWidth}px`}}
          >
            <h1 className="text-align-center">Exit without completing test?</h1>
            <div>
              <h3 className="underline text-align-center">This will delete this entry</h3>
            </div>
            <div className="vertical-btn-container">
              <div
                className="btn-div vertical-btn"
                onClick={() => {
                  this.props.deleteTestSet(this.props.testSetIndex)
                  this.props.changePage('collagePage')
                }}
              >
                <p>Yes</p>
              </div>
              <div
                className="btn-div vertical-btn"
                onClick={() => this.setState({slideUp: false})}
              >
                <p>No</p>
              </div>
            </div>
          </div>
        }
        <div className="testSet-header relative" onClick={() => this.scroll(this.myRef)}>
          <img className="upper-left svg-48" onClick={() => this.setState({modal: true, slideUp: true})} src={xCircleBlack} alt="cancel" />
          <h2 className="text-align-center side-margin-2em">Take a photo of your skin</h2>
        </div>
        <div className="test-set-header flex-column space-evenly margin-1em">
          <div className="flex space-between">
            <p className="margin-0">{'Image ' + helpers.timeStampToDate(Date.now())}</p>
            <div className="flex">
              <p className="margin-0">{this.handleDate()}</p>
              <Deck />
            </div>
          </div>
          <h3 className="margin-0">{testSet.moleLocation}</h3>
        </div>
        <div
          ref={this.myRef}
          className="carousel-container"
          style={{ width: contentWidth }}
        >
          {/* Style for 3 images (overflows) */}
          {/* <div
            className="three-image-carousel"
            style={{width: `${(contentWidth - (2 * fontSize)) * 3 + fontSize}px`}}
          > */}
          <div
            className="three-image-carousel"
          >
            <div style={uploadContainerStyle}>
              <Upload
                testSetIndex={this.state.testSetIndex}
                imgIndex={0}
                changePage={this.props.changePage}
                pages={this.props.pages}
              />
            </div>
          </div>
        </div>
        <div className="testSet-checklist">
          <h3>CHECKLIST</h3>
          <p className="margin-0">- Skin is well-lit</p>
          <p className="margin-0">- Affected area should be centered and clear</p>
          <p className="margin-0">- Image should show skin only</p>
        </div>
        <div id="notes-container">
        <h3>CONCERNS</h3>
          <textarea
            placeholder="optional, maximum length: 280 characters"
            maxLength="280"
            onChange={this.handleNotes}
            value={testSet.notes && testSet.notes}
          ></textarea>
        </div>
        <div className="flex-column flex-end">
          <div
            // className="btn-div margin-1em background-gray"
            className={this.props.test.image && this.props.test.image.croppedImageDataURL ? 'btn-div margin-1em background-gray pulse2' : 'btn-div margin-1em background-gray'}
            onClick={
              this.props.test.image && this.props.test.image.croppedImageDataURL ?
              () => {
                const currentTestId = this.props.currentTest ? this.props.currentTest.Id : null
                this.props.submitTest(this.props.patientData, this.props.test, this.props.storage, firebase.auth().currentUser.uid, this.state.notes, currentTestId)
                // this.props.changePage('collagePage')
              } :
              () => this.props.storePayload({
                userMessage: 'Please add a photo of the affected area',
                notificationType: 3,
                notificationIcon: 'warning'
              })
              // () => alert('Please add a photo of the affected area')
            }
          >
            <p
              // className={testSet.croppedImageUrls.length < 3 ? null : 'pulse'}
              ref={this.myRef2}
            >Done
            </p>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  storage: state.firebaseReducer.storage,
  database: state.firebaseReducer.database,
  firestore: state.firebaseReducer.firestore,
  patientData: state.imageReducer.patientData,
  croppedImageUrls: state.imageReducer.images.croppedImageUrls,
  croppedImageFiles: state.imageReducer.images.croppedImageFiles,
  currentTest: state.imageReducer.currentTest,
  test: state.imageReducer.test,
  notes: state.imageReducer.notes,
  testSetIndex: state.imageReducer.testSetIndex,
  imgIndex: state.imageReducer.imgIndex,
  isEditing: state.imageReducer.isEditing,
  newTestId: state.imageReducer.newTestId,
})

export default connect(mapStateToProps, { storeTestSetAndImgIndex, storeNotes, deleteTestSet, submitTest, storePayload, })(TestSet)