import React, {Fragment} from 'react'
import {connect} from 'react-redux'
import {Link} from "react-router-dom";
import {storePayload} from '../actions/storePayload'
import dm from '../Images/dm48.svg'
import whiteDm from '../Images/WhiteDm.svg'
import '../styles/WithNav.css'

// Wrapper for page to render with Nav
function Nav(props) {
  const {data} = props.patientData
  return (
    <Fragment>
      <nav className={props.isSettings ? 'inverted' : ''}>
        <Link to='/'>
          <img id='dm' src={props.isSettings ? whiteDm : dm} alt='Dm' onClick={() => props.changePage('collagePage')}/>
        </Link>
        <div
          className='settings-btn'
          onClick={() => {
            if (props.subscriptionData && props.subscriptionData.subPlan !== 'None' && props.subscriptionData.subPlan !== 'error') {
              props.storePayload({
                isSettings: !props.isSettings,
                isTooltip: false,
              })
            }
          }}
        >
          <p>
            {data && data.Info && data.Info.FirstName.slice(0, 1) + data.Info.LastName.slice(0, 1)}
          </p>
        </div>
      </nav>
    </Fragment>
  )
}

const mapStateToProps = (state) => ({
  storage: state.firebaseReducer.storage,
  database: state.firebaseReducer.database,
  firestore: state.firebaseReducer.firestore,
  isSettings: state.imageReducer.isSettings,
  patientData: state.imageReducer.patientData,
  subscriptionData: state.imageReducer.subscriptionData,
})

export default connect(
    mapStateToProps,
    {storePayload},
)(Nav)