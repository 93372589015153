import firebase from 'firebase/app'
import 'firebase/analytics'
import {signIn} from './signIn'

export const signUp = (form, firestore, cookies) => (dispatch) => {
  let dataToSend
  const mixpanel = require('mixpanel-browser')
  const queryString = window.location.search
  const urlParams = new URLSearchParams(queryString)
  let orgId = urlParams.get('orgId')
  let physicianId = urlParams.get('state')
  const createPatientURL = process.env.REACT_APP_URL + 'sonnetCreatePatient'
  let isLinked
  if (!orgId || !physicianId) {
    isLinked = false
    orgId = 'Lazarus-Sonnet'
    physicianId = 'shuXDMJRv4H6lQWOYtP3'
  } else {
    isLinked = true
  }
    // dispatch({
    //   type: 'STORE_PAYLOAD',
    //   payload: {
    //     userMessage: 'Please make sure you have the correct url',
    //     notificationType: 1,
    //     notificationIcon: 'warning',
    //   }
    // })
  if (!form.firstName || !form.lastName || !form.sex ||
    !form.email || !form.phone || !form.password ||
    !form.confirmPassword || !form.day || !form.month || !form.year) {
    dispatch({
      type: 'STORE_PAYLOAD',
      payload: {
        userMessage: 'Please complete the form',
        notificationType: 1,
        notificationIcon: 'warning',
      }
    })
  } else if (form.day > 31 || form.month > 12 || form.year < 1900) {
    dispatch({
      type: 'STORE_PAYLOAD',
      payload: {
        userMessage: 'Please make sure birthday is in mm/dd/yyyy format, e.g. 12/25/1989',
        notificationType: 1,
        notificationIcon: 'warning',
      }
    })
  } else if (
      form.day.toString().length !== 2 ||
      form.month.toString().length !== 2 ||
      form.year.toString().length !== 4
    ) {
    dispatch({
      type: 'STORE_PAYLOAD',
      payload: {
        userMessage: 'Please make sure birthday is in mm/dd/yyyy format, e.g. 01/01/1989',
        notificationType: 1,
        notificationIcon: 'warning'
      }
    })
  } else {
    dispatch({
      type: 'STORE_PAYLOAD',
      payload: {
        isLoading: true,
      },
    })
    const timeStamp = Date.now()
    const birthday = `${form.year}-${form.month}-${form.day}`
    dataToSend = {
      PatientID: null,
      PatientData: {
        Contact: {
          Email: form.email,
          Phone: form.phone,
        },
        Info: {
          Birthday: birthday,
          FirstName: form.firstName,
          LastName: form.lastName,
          Name: `${form.firstName} ${form.lastName}`,
          PreferredGender: form.gender,
          Sex: form.sex,
        },
      },
      OrgPatientData: {
        ID: null,
        Birthday: birthday,
        Email: form.email,
        Phone: form.phone,
        FirstName: form.firstName,
        LastName: form.lastName,
        Created: timeStamp,
        CreatedBy: physicianId,
        LastUpdate: timeStamp,
        Name: `${form.firstName} ${form.lastName}`,
        PreferredGender: form.gender,
        Sex: form.sex,
        SharedWith: {
          [physicianId]: true
        },
        Source: 'Patient'
      },
      Physician: physicianId,
      Org: orgId,
    }
    if (isLinked) {
      dataToSend.Linked =
        {
          Main: orgId,
          [orgId]: physicianId,
        }
    }

    // console.log('mixpanel', mixpanel)
    mixpanel.track('Attempt Sign Up', dataToSend)
    if (form.password === form.confirmPassword) {
      firebase.auth().createUserWithEmailAndPassword(
        form.email,
        form.password,
      )
          .then((res) => {
            firebase.analytics().logEvent('SonnetSignUp', {
              source: "Patient Portal Sonnet"
            })
            dataToSend['PatientID'] = res.user.uid
            dataToSend.OrgPatientData.ID = res.user.uid
            sendPatientData(dataToSend)
          })
          .catch((err) => {
            console.error(err)
            handleSignUpError()
            mixpanel.track('Attempt Sign Up', err)
          })
    } else {
      dispatch({
        type: 'STORE_PAYLOAD',
        payload: {
          isLoading: false,
          userMessage: 'Passwords do not match',
          notificationType: 3,
          notificationIcon: 'warning',
        }
      })
    }

    function sendPatientData(dataToSend) {
      const jsonData = JSON.stringify(dataToSend)
      fetch(createPatientURL, {
        method: 'POST',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
        },
        body: jsonData,
      })
      .then((res) => {
        if (res.ok) {
          res.json()
            .then((data) => {
              console.log('success')
            })
            .catch((error) => {
              console.log('error', error)
              handleSignUpError()
            })
          dispatch(signIn(form.email, form.password, cookies, firestore))
          mixpanel.track('Sign Up Success', dataToSend)
          dispatch({
            type: 'STORE_PAYLOAD',
            payload: {
              isLoading: false,
              userMessage: 'Welcome! Your account has been created',
              notificationType: 1,
              notificationIcon: 'check',
              isLoading: false,
            },
          })
        } else {
          handleSignUpError()
        }
      })
      .catch(() => {
        handleSignUpError()
      })
    }
  }

  function handleSignUpError() {
    mixpanel.track('Sign Up Failure', dataToSend)
    dispatch({
      type: 'STORE_PAYLOAD',
      payload: {
        isLoading: false,
        userMessage: 'There was an error on sign-up. Please try again and contact us if the problem persists.',
        notificationType: 3,
        notificationIcon: 'warning',
        isLoading: false,
      },
    })
  }
}