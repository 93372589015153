import React, {Component, Fragment} from 'react'
import ReactCrop from 'react-image-crop'
import {connect} from 'react-redux'
import 'react-image-crop/dist/ReactCrop.css'
import '../index.scss'
import 'firebase/auth'
import loadImage from 'blueimp-load-image'
import {clearImages} from '../actions/clearImagesAction'
import {storeCroppedImage} from '../actions/storeCroppedImageAction'
import {spliceImage} from '../actions/spliceImageAction'
import {storeOriginalImage} from '../actions/storeOriginalImageAction'
import {storeImageIndex} from '../actions/storeImageIndexAction'
import helpers from '../helpers.js'
import arrow from '../Images/arrow.svg'

class ImageUpload extends Component {
  constructor(props) {
    super(props)
    this.state = {
      cropHasBeenAdjusted: false,
      crop: {
        unit: '%',
        width: 100,
        // height: 100,
        aspect: 1 / 1,
      },

      minWidth: null,
      maxWidth: null,
      isImageSelected: false,
      imageFile: [],
      imageUrl: [],
      croppedImageFiles: [],
      croppedImageUrls: [],
      croppedImageFile: null,
      croppedImageUrl: null,
      isImageCropped: false,

      loading: false,
      tooSmall: false,
      showCollage: false,
      slideUp: true,
    }

    this.setImage = this.setImage.bind(this)
    this.onClickAddImage = this.onClickAddImage.bind(this)
    this.onSelectImage = this.onSelectImage.bind(this)
    this.onImageLoaded = this.onImageLoaded.bind(this)
    this.onCropComplete = this.onCropComplete.bind(this)
    this.onCropChange = this.onCropChange.bind(this)
    this.onClickCancelCrop = this.onClickCancelCrop.bind(this)
  }

  detectApple() {
    const toMatch = [
      /webOS/i,
      /iPhone/i,
      /iPad/i,
      /iPod/i,
    ];

    return toMatch.some((toMatchItem) => {
      return navigator.userAgent.match(toMatchItem);
    })
  }

  setImage() {
    const i = this.props.testSetIndex
    const j = this.props.imgIndex
    const imageSrc = this.props.originalImage.url
    // orientation = exif data orientation value
    // To prevent 90 degree image rotation, use 1 for apple, true for android
    const orientation =
      helpers.detectApple() ? {orientation: 1} : {orientation: true}
    // LoadImage takes an img, runs a canvas of the image through the callback function, using the orientation to configure
    loadImage(imageSrc, helpers.loadImageCB.bind(this), orientation) // exif orientation options: 1-8 or true(default?)
  }

  componentDidMount() {
    this.setImage()
  }

  onClickAddImage(event) {
    this.props.storeImageIndex(null)
    this.onSelectImage(event)
  }

  onSelectImage(event) {
    if (this.state.croppedImageFiles.length === 0) {
      this.props.togglePage('cropperPage')
    }
    const orientation =
      helpers.detectApple() ? {orientation: 1} : {orientation: true}
    loadImage(
        event.target.files[0],
        helpers.loadImageCB.bind(this),
        orientation,
    )
  }

  onImageLoaded(image) {
    this.imageRef = image
  }

  onCropComplete(crop) {
    this.makeClientCrop(crop);
  }

  onCropChange(crop, percentCrop) {
    this.setState({crop})
  }

  async makeClientCrop(crop) {
    if (this.imageRef && crop.width && crop.height) {
      const croppedImage = await helpers.getCroppedImg.bind(this)(
          this.imageRef,
          crop,
          'newFile.jpeg',
      )
      const newCroppedImageUrl = croppedImage[0]
      const newCroppedImageFile = croppedImage[1]

      this.setState({
        croppedImageUrl: newCroppedImageUrl,
        croppedImageFile: newCroppedImageFile
      });
    }
  }

  onClickDone() {
    if (!this.state.isImageCropped) {
      this.setState({
        cropHasBeenAdjusted: false,
        isImageCropped: true,
      })
    } else {
      const croppedImageDataURL = this.state.canvas.toDataURL('image/png');
      // console.log('cropped image data url', croppedImageDataURL)
      const croppedImageUrls = [
        ...this.state.croppedImageUrls,
        this.state.croppedImageUrl,
      ]
      const croppedImageFiles = [
        ...this.state.croppedImageFiles,
        this.state.croppedImageFile,
      ]
      this.setState({
        isImageSelected: false,
        loading: true,
        croppedImageUrls,
        croppedImageFiles,
        showCollage: true,
      })
      // if (this.props.indexOfImageToReplace !== null) {
      //   console.log('index of image to replace is not null')
      //   // Image is being replace
      //   this.props.spliceImage(
      //       this.state.croppedImageUrl,
      //       this.state.croppedImageFile,
      //       this.props.indexOfImageToReplace,
      //   )
      // } else { // Image is being uploaded
        this.props.storeCroppedImage(
            this.state.croppedImageUrl,
            this.state.croppedImageFile,
            croppedImageDataURL,
        )
        this.props.changePage('testSetPage')
      // }
    }
  }

  onClickCancelCrop(event) {
    event.stopPropagation()
    if (this.state.isImageCropped) {
      this.setState({
        croppedImageUrl: null,
        croppedImageFile: null,
        isImageCropped: false,
      })
    } else {
      this.setState({
        cropHasBeenAdjusted: false,
        croppedImageUrl: null,
        croppedImageFile: null,
        imageUrl: [],
        imageFile: [],
        isImageSelected: false,
        crop: {
          unit: '%',
          height: 100,
          width: 100,
          aspect: 1 / 1,
        },
      })
      this.props.storeOriginalImage(null, null)
      this.props.changePage('testSetPage')
    }
    // document.getElementById('AddImageInput').value = null
  }

  render() {
    const contentWidth = document.getElementsByClassName('App')[0].offsetWidth
    // Code for making cropped image appear in same place as carousel image if enough space
    // if (this.state.isImageCropped) {
    //   const cropperContainer = document.getElementsByClassName('cropper-container')
    //   const testSetTop = 11.5 * fontSize
    //   const submitButtonHeight = 4 * fontSize
    //   const minHeight = testSetTop + contentWidth + submitButtonHeight
    //   if (cropperContainer && window.innerHeight >= minHeight) {
    //     cropperContainer[0].style.margin = `${4.5 * fontSize}px 0 0`
    //   }
    // }

    // const mouseClickEvents = ['mousedown', 'click', 'mouseup'];
    // var element = document.querySelector('label[id="upload-image-label"]')
    // function simulateMouseClick(element){
    //   mouseClickEvents.forEach(mouseEventType =>
    //     element.dispatchEvent(
    //       new MouseEvent(mouseEventType, {
    //           view: window,
    //           bubbles: true,
    //           cancelable: true,
    //           buttons: 1
    //       })
    //     )
    //   );
    // }

    // // var element = document.getElementById("upload-image-label")
    // simulateMouseClick(element);


    const cropper = !this.state.isImageCropped ?
      <ReactCrop
        className='crop-image'
        src={this.state.imageUrl}
        crop={this.state.crop}
        ruleOfThirds
        onImageLoaded={this.onImageLoaded}
        onComplete={this.onCropComplete}
        onChange={this.onCropChange}
        minWidth={this.state.minWidth}
        minHeight={this.state.minWidth}
        maxWidth={this.state.maxWidth}
        maxHeight={this.state.maxHeight}
        keepSelection={'true'}
      /> :
      <img className='crop-image' src={this.state.croppedImageUrl} alt=""></img>

    const cropperContainer = this.state.isImageSelected && (
      <div
        className="image-upload-container"
        style={{ minHeight: `${window.innerHeight}px`}}
      >
        <div className="testSet-header relative">
          <div className="upper-left flex justify-center align-center">
            <div className="upper-left cancel-circle3 flex justify-center align-center">
              <img
                className="svg-48"
                src={arrow}
                alt="cancel"
                onClick={(event) => this.onClickCancelCrop(event)}
              />
            </div>
          </div>
          <h2 className="text-align-center side-margin-2em">Center the mole</h2>
        </div>
        <div className="cropper-container flex align-center justify-center">
          <div className="image-container">
            {cropper}
          </div>
          { this.state.tooSmall &&
            <div
              className={
                this.state.slideUp ?
                'full-modal slideInUp' :
                'full-modal slideOutDown'
              }
              style={{width: `${contentWidth}px`}}
            >
              <h1 className="text-align-center">Warning</h1>
              <div>
                <h3 className="underline text-align-center">
                  Image should be at least 480 x 480 pixels
                </h3>
                <h3 className="underline text-align-center">
                  For best results, choose a photo with higher resolution
                </h3>
              </div>
              <div className="vertical-btn-container">
                <div
                  className="btn-div vertical-btn"
                  onClick={() => {
                    this.setState({slideUp: false})
                  }}
                >
                  <p>Okay</p>
                </div>
              </div>
            </div>
          }
        </div>
        <div
          className='btn-div margin-1em'
          onClick={this.onClickDone.bind(this)}
        >
          <p>{ this.state.isImageCropped ? 'Done' : 'Crop' }</p>
        </div>
      </div>
    )

    return (
      <Fragment>
        {(window.orientation === 90 || window.orientation === -90) ?
          <div
            className="flipped">
            <h2>Please use device in vertical view</h2>
          </div> :
        <Fragment>
          <form>
            {this.state.isImageSelected &&
              <Fragment>
                {cropperContainer}
              </Fragment>
            }
          </form>
          {this.props.cropped}
        </Fragment>
        }
      </Fragment>
    )
  }
}

const mapStateToProps = (state) => ({
  storage: state.firebaseReducer.storage,
  database: state.firebaseReducer.database,
  firestore: state.firebaseReducer.firestore,
  croppedImageUrls: state.imageReducer.images.croppedImageUrls,
  croppedImageFiles: state.imageReducer.images.croppedImageFiles,
  indexOfImageToReplace: state.imageReducer.indexOfImageToReplace,
  test: state.imageReducer.test,
  originalImage: state.imageReducer.originalImage,
  testSetIndex: state.imageReducer.testSetIndex,
  imgIndex: state.imageReducer.imgIndex,
})

export default connect(
    mapStateToProps,
    {storeCroppedImage,
      storeOriginalImage,
      clearImages,
      spliceImage,
      storeImageIndex},
)(ImageUpload)
