import React, {useState, useEffect} from 'react'
import firebase from 'firebase/app'
import {connect} from 'react-redux'
import {withCookies} from 'react-cookie'

// Actions
import {signIn} from '../actions/Auth/signIn'
import {storePayload} from '../actions/storePayload'

// Components
import {Input, Button} from '@anishp16/lazarus-cds'
import SignInForm from './SignInForm'
import SignUpForm from './SignUpForm'
// import {Input, Button} from 'lazarus-cds'

// Images
import lazarus from '../Images/Lazarus.svg'
import dm from '../Images/dm48.svg'

// Styles
import '@anishp16/lazarus-cds/dist/index.css'
import '../styles/Auth.css'
// import 'lazarus-cds/dist/index.css'

/*
  Auth renders sign-up and sign-in forms
*/

function Auth(props) {
  // const demoData = {
  //   email: 'anishp16@lazarus.enterprises',
  //   password: 'password1',
  // }
  const [userData, setUserData] = useState({})
  const [isSignUpPage, setIsSignUpPage] = useState(null)
  const [forgotPassword, setForgotPassword] = useState(false)

  useEffect(() => {
    const queryString = window.location.search
    const urlParams = new URLSearchParams(queryString)
    let orgId = urlParams.get('orgId')
    let physicianId = urlParams.get('state')
    const url = window.location.href
    const includesSignUp = url.toUpperCase().includes('SIGNUP')
    if ((orgId && physicianId) || includesSignUp) {
      setIsSignUpPage(true)
    }
  }, [])

  useEffect(() => {
    const isDatabaseReady =
      props.database && Object.keys(props.database).length > 0
    if (props.cookies && isDatabaseReady) {
      const email = props.cookies.get('email')
      const password = props.cookies.get('password')
      if (email && password) {
        props.signIn(email, password, props.cookies, props.firestore)
      }
    }
  }, [props.database])

  useEffect(() => {
    if (props.patientData && props.patientData.data) {
      props.changePage('collagePage')
    }
  }, [props.patientData])

  // Clear cookies on sign in failure
  useEffect(() => {
    if (props.authStatus && props.authStatus === 'failure') {
      props.cookies.remove('email', {path: '/'})
      props.cookies.remove('password', {path: '/'})
      setIsLoading(false)
    }
  }, [props.authStatus])

  const onChangeForm = (event) => {
    setUserData({
      ...userData,
      [event.target.name]: event.target.value,
    })
  }

  const onChangeSex = (option) => {
    setUserData({
      ...userData,
      sex: option,
    })
  }

  const onClickSignIn = () => {
    props.signIn(
        userData.email,
        userData.password,
        props.cookies,
        props.firestore,
    )
  }

  const sendPasswordResetEmail = () => {
    firebase.auth().sendPasswordResetEmail(userData.email)
        .then((res) => {
          props.storePayload({
            userMessage: 'Please check your email to change your password',
            notificationType: 3,
            notificationIcon: 'check',
          })
          setForgotPassword(false)
        })
        .catch((err) => {
          console.log('error', err)
          alert('Email not found. Please try again')
        })
  }

  const onClickForgotPassword = () => {
    setForgotPassword(!forgotPassword)
  }

  return (
    <div className={isSignUpPage ? 'sign-up-page auth-page' : 'auth-page'} style={{ minHeight: `${window.innerHeight}px` }}>
      <div className='top'>
        <div className='logo-container'>
          <img className='lazarus' src={lazarus} alt='Lazarus' />
        </div>
        <div className='dm-container'>
          <img className='auth-dm' src={dm} alt= 'Dm' />
          <div>
            <p>Clinical Decision Support</p>
            <p className='bold'>Dermatology</p>
          </div>
        </div>
      </div>
      <div className='bottom'>
        {isSignUpPage ?
          <SignUpForm
            onChangeForm={onChangeForm}
            onChangeSex={onChangeSex}
            match={props.match}
            firestore={props.firestore}
          /> :
          <SignInForm
            onChangeForm={onChangeForm}
            onClickSignIn={onClickSignIn}
            sendPasswordResetEmail={sendPasswordResetEmail}
            forgotPassword={forgotPassword}
            onClickForgotPassword={onClickForgotPassword}
          />
        }
        <p
          onClick={() => {
            setIsSignUpPage(!isSignUpPage)
            if (isSignUpPage) {
              const mixpanel = require('mixpanel-browser')
              mixpanel.track('User went to Sign-In from Sign-Up', {})
            }
          }}
        >
          {!isSignUpPage ? 'Sign Up' : 'Sign In'}
        </p>
      </div>
    </div>
  )
}

const mapStateToProps = (state, ownProps) => ({
  storage: state.firebaseReducer.storage,
  database: state.firebaseReducer.database,
  firestore: state.firebaseReducer.firestore,
  patientData: state.imageReducer.patientData,
  signedUp: state.firebaseReducer.signedUp,
  isLoading: state.imageReducer.isLoading,
  userMessage: state.imageReducer.userMessage,
  authStatus: state.firebaseReducer.authStatus,
  cookies: ownProps.cookies,
})

export default withCookies(connect(
  mapStateToProps,
  {signIn, storePayload},
)(Auth))