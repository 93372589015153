import { combineReducers } from 'redux'
import firebaseReducer from './firebaseReducer'
import imageReducer from './imageReducer'

const appReducer = combineReducers({
  firebaseReducer: firebaseReducer,
  imageReducer: imageReducer
})

const rootReducer = (state, action) => {
  if (action.type === 'CLEAR_STORE') {
    state = {
      ...state,
      imageReducer: {
        patientData: {},
        subscriptionData: {},
        images: {
          croppedImageUrls: [],
          croppedImageFiles: [],
          croppedImageDataURLS: [],
        },
        testSets: [],
      },
    }
  }
  return appReducer(state, action)
}

export default rootReducer