// Dependencies
import React from 'react'
import dm from '../Images/dm48.svg'
import {connect} from 'react-redux'
import {withCookies} from 'react-cookie'
import firebase from 'firebase/app'

// Actions
import {signOut} from '../actions/Auth/signOut'

// Components
import {
  Button,
} from '@anishp16/lazarus-cds'

// Styles
import '@anishp16/lazarus-cds/dist/index.css'
import '../styles/Onboard.css'

/*
  Onboard is the "gateway" to the ChoosePlan component. It will render
  if patient is logged in and patientData doesn't have Sub.
*/

function Onboard(props) {
  const onClickBeginSetup = () => {
    const mixpanel = require('mixpanel-browser')
    mixpanel.track('Click Begin Setup', {})
    props.changePage('choosePlanPage')
  }
  const onClickLogOut = async () => {
    await props.cookies.remove('email', {path: '/'})
    await props.cookies.remove('password', {path: '/'})
    const time = Date.now()
    mixpanel.track('Logout During Onboarding', {})
    props.signOut()
  }
  return (
    <div className='onboard-page'>
      <header>
        <img src={dm} alt='dm' />
        <p>Clinical Decision Support</p>
        <p className='bold'>Dermatology</p>
      </header>
      <div>
        <p>Welcome to the Lazarus Patient Portal</p>
      </div>
      <div>
        <p className='font-size-125'>
          This app allows you to keep a photo timeline of your skin and share it to your doctor
        </p>
      </div>
      <div>
        <p>Use this application to upload images and track your skin.
        Any photos uploaded here will be shown on your physician's dashboard.
        </p>
      </div>
      <footer>
        <Button
          text='Begin Setup'
          type={1}
          onClick={onClickBeginSetup}
        />
        <p
          onClick={onClickLogOut}
        >
          Log out
        </p>
      </footer>
    </div>
  )
}

const mapStateToProps = (state, ownProps) => ({
  storage: state.firebaseReducer.storage,
  database: state.firebaseReducer.database,
  firestore: state.firebaseReducer.firestore,
  cookies: ownProps.cookies,
})

export default withCookies(connect(
    mapStateToProps,
    {signOut},
)(Onboard))