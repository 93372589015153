
import { fetchPatientData } from '../fetchPatientData'
import { refreshSubData } from '../refreshSubData'

export const checkIfPatientExists = (
  cookies,
  auth,
  firestore,
  patient_Id
) => (dispatch) => {
  firestore.collection('LCDS')
    .doc('Something')
    .collection('Patients')
    .doc(patient_Id)
    .get()
    .then((patientDoc) => {
      if (patientDoc.exists) {
        const patientData = patientDoc.data()
        if (patientData.Contact) {
          dispatch(fetchPatientData(cookies, auth, firestore, [patient_Id])) // Takes an array of patient Ids
          dispatch(refreshSubData(firestore, patient_Id))
        } else {
          auth.signOut()
          cookies.remove('email', { path: '/' })
          cookies.remove('password', { path: '/' })
          dispatch({
            type: 'STORE_PAYLOAD',
            payload: {
              userMessage:
                'There was an error signing in, you may be signing in to the wrong portal.',
              notificationType: 3,
              notificationIcon: 'warning',
              isSignedIn: false,
              isLoading: false,
            },
          })
        }
      } else {
        auth.signOut()
        cookies.remove('email', { path: '/' })
        cookies.remove('password', { path: '/' })
        dispatch({
          type: 'STORE_PAYLOAD',
          payload: {
            userMessage:
              'There was an error signing in, you may be signing in to the wrong portal.',
            notificationType: 3,
            notificationIcon: 'warning',
            isSignedIn: false,
            isLoading: false,
          },
        })
      }
    }).catch((error) => {
      auth.signOut()
      cookies.remove('email', { path: '/' })
      cookies.remove('password', { path: '/' })
      dispatch({
        type: 'STORE_PAYLOAD',
        payload: {
          userMessage:
            'There was an error signing in, you may be signing in to the wrong portal.',
          notificationType: 3,
          notificationIcon: 'warning',
          isSignedIn: false,
          isLoading: false,
        },
      })
    });
}