// Dependencies
import React, {Fragment, useState, useEffect} from 'react'
import {connect} from 'react-redux'
import {withCookies} from 'react-cookie'
import {Redirect} from 'react-router-dom'
import StripeCheckout from 'react-stripe-checkout'
import firebase, { analytics } from 'firebase/app'
import helpers from '../helpers.js'

// Actions
import {storePayload} from '../actions/storePayload'
import {signOut} from '../actions/Auth/signOut'
import {choosePlan} from '../actions/choosePlan'

// Components
import {Button, Modal} from '@anishp16/lazarus-cds'
// import LinkPhysician from './LinkPhysician'
import ChangePassword from './ChangePassword'

// Images
import leftArrow from '../Images/LeftArrow.svg'
import rightArrow from '../Images/RightArrow.svg'
import warning from '../Images/yellowWarning.svg'

// Styles
import '@anishp16/lazarus-cds/dist/index.css'
import '../styles/Settings.css'

function Settings(props) {
  const {data} = props.patientData
  const [isChangePassword, setIsChangePassword] = useState(false)
  const [isChangePlan, setIsChangePlan] = useState(false)
  const [isPlanSelectionVisible, setIsPlanSelectionVisible] = useState(false)
  const [isConfirmationModal, setIsConfirmationModal] = useState(false)
  const [isConfirmationModalShowing, setIsConfirmationModalShowing] = useState(false)
  const mailLink = 'mailto:support@lazarusai.com?subject=Patient%20Support'
  const changePaymentURL = process.env.REACT_APP_URL + 'sonnetStripeChangePayment'

  useEffect(() => {
    if (props.subscriptionData && (props.subscriptionData.subPlan === 'None' || props.subscriptionData.subPlan === 'error')) {
      setIsChangePlan(true)
      setIsPlanSelectionVisible(true)
    }
  }, [props.subscriptionData])

  const onClickChangeCreditCardToken = (token) => {
    props.storePayload({
      isLoading: true,
    })
    const myHeaders = new Headers()
    myHeaders.append('Content-Type', 'application/json')

    const raw = JSON.stringify({
      Source: token['id'],
      patient_id: firebase.auth().currentUser.uid,
    });

    const requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow',
    };

    fetch(changePaymentURL, requestOptions)
        // .then((response) => console.log('response', response))
        .then(() => {
          props.storePayload({
            isLoading: false,
            userMessage: 'Your payment has been updated',
            notifcationType: 2,
            notificationIcon: 'check',
          })
        })
        .catch((error) => console.log('error', error))
  }

  const onClickStart = (token, plan) => {
    const data = {
      ...props.subscriptionData,
      name: props.patientData.data.Info.Name,
      email: props.patientData.data.Info.ContactEmail,
      patient_id: firebase.auth().currentUser.uid,
    }
    props.choosePlan(props.firestore, token, plan, data)
  }

  const currentPlan = () => {
    if (!props.subscriptionData.subPlan) {
      return 'No Plan'
    } else if (props.subscriptionData.subPlan === 'patient-monthly') {
      return 'Monthly'
    } else if (props.subscriptionData.subPlan === 'patient-yearly') {
      return 'Yearly'
    } else {
      return 'None'
    }
  }
  
  return (
    <Fragment>
      {data ? (
      <Fragment>
        <div
          className={
            isConfirmationModalShowing ?
            'settings-page z-index-5' : 
            'settings-page'
          }
        >
          {/* {isLinkProvider &&
            <LinkPhysician />
          } */}
          {isChangePassword ?
          <ChangePassword cancel={() => setIsChangePassword(false)} /> :
            <Fragment>
              <div className='settings-top'>
                <div onClick={() => {
                  if (props.subscriptionData && props.subscriptionData.subPlan !== 'None' && props.subscriptionData.subPlan !== 'error') {
                    props.storePayload({isSettings: false})
                  }
                }}>
                  <img className='back-arrow' src={leftArrow} alt='back' />
                </div>
                <div className='patient-info'>
                  <p className='patient-name'>{data.Info.Name}</p>
                  <p className='patient-email'>{data.Contact.Email}</p>
                </div>
                <div className='plan-container'>
                  <p>{'Current Plan: ' + currentPlan()}</p>
                  <p
                    className='change-plan underline'
                    onClick={() => {
                      setIsChangePlan(true)
                      setIsPlanSelectionVisible(!isPlanSelectionVisible)
                    }}
                  >
                    Change Plan
                  </p>
                  {isChangePlan &&
                    <div className={isPlanSelectionVisible ? 'expand-down' : 'shrink-up'}>
                      <StripeCheckout
                        stripeKey={process.env.REACT_APP_STRIPE_KEY}
                        token={(token) => onClickStart(token, 'patient-monthly')}
                        email={props.patientData.data ? props.patientData.data.Contact.Email : null}
                      >
                        <div className='plan-card'>
                          <div>
                            <p>MONTHLY PLAN</p>
                            <p>$5/month</p>
                          </div>
                          <img src={rightArrow} alt='arrow' />
                        </div>
                      </StripeCheckout>
                      <StripeCheckout
                        stripeKey={process.env.REACT_APP_STRIPE_KEY}
                        token={(token) => onClickStart(token, 'patient-yearly')}
                        email={props.patientData.data ? props.patientData.data.Contact.Email : null}
                      >
                        <div className='plan-card blue-card'>
                          <div>
                            <p>YEARLY PLAN</p>
                            <p>$50/year</p>
                          </div>
                          <img src={rightArrow} alt='arrow' />
                        </div>
                      </StripeCheckout>
                      <p
                        className='red-text'
                        onClick={() => {
                          setIsConfirmationModal(true)
                          setIsConfirmationModalShowing(!isConfirmationModalShowing)
                        }}
                      >
                        Cancel Subscription
                      </p>
                    </div>
                  }
                  <div className='modal-container'>
                    {isConfirmationModal &&
                      <Modal
                        style={{maxWidth: '100%'}}
                        confirmationFunc={() => {
                          props.choosePlan(props.firestore, null, null, null, true)
                          setIsConfirmationModalShowing(false)
                        }}
                        cancelationFunc={() => setIsConfirmationModalShowing(false)}
                        confirmationText={'Yes'}
                        cancelationText={'No'}
                        message={'Are you sure you would like to cancel your subscription?'}
                        image={warning}
                        time={helpers.timeStampToTime(Date.now())}
                        isVisible={isConfirmationModalShowing}
                      />
                    }
                  </div>
                </div>
                <StripeCheckout
                  stripeKey={process.env.REACT_APP_STRIPE_KEY}
                  token={(token) => onClickChangeCreditCardToken(token)}
                  email={
                    props.patientData.data && props.patientData.data.Contact.Email ?
                    props.patientData.data.Contact.Email :
                    null
                  }
                >
                  <div className='payment-container'>
                    <div className='flex space-between'>
                      <span>PAYMENT</span>
                      <span className='grey-text'>Edit</span>
                    </div>
                    <div className='credit-card'>
                      <div>
                        <div className='gray-div' />
                        <p>{props.subscriptionData.brand} {props.subscriptionData.type} card</p>
                      </div>
                      <p className='bold'>****{props.subscriptionData.dig4}</p>
                    </div>
                  </div>
                </StripeCheckout>
              </div>
              <Button
                type={2}
                text='&#128279; Link Provider'
                style={{
                  marginBottom: '1em',
                  backgroundColor: 'rgb(119, 237, 253)',
                  borderRadius: '3em'
                }}
                onClick={() => {
                  props.changePage('linkPhysician')
                  props.storePayload({isSettings: false})
                }}
              />
              <div className='settings-bottom'>
                <a href={mailLink} target='_blank' className='underline'>
                  Contact Us
                </a>
                <div>
                  <Button
                    text='Change Password'
                    type={2}
                    onClick={() => {
                      setIsChangePassword(!isChangePassword)
                    }}
                  />
                  <Button
                    text='Sign Out'
                    type={1}
                    onClick={async () => {
                      await props.cookies.remove('email', {path: '/'})
                      await props.cookies.remove('password', {path: '/'})
                      props.signOut()
                    }}
                  />
                </div>
              </div>
            </Fragment>
            }
          </div>
      </Fragment>
      ) :
      <div />
      }
    </Fragment>
  )
}

const mapStateToProps = (state, ownProps) => ({
  storage: state.firebaseReducer.storage,
  database: state.firebaseReducer.database,
  firestore: state.firebaseReducer.firestore,
  patientData: state.imageReducer.patientData,
  subscriptionData: state.imageReducer.subscriptionData,
  cookies: ownProps.cookies,
})

export default withCookies(connect(
    mapStateToProps,
    {storePayload, signOut, choosePlan},
)(Settings))
