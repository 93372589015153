import React, {Fragment} from 'react'
import {connect} from 'react-redux'
import '../styles/WithNav.css'
import Nav from './Nav'

// Wrapper for page to render with Nav
function WithNav(props) {
  const Component = props.component
  return (
    <Fragment>
      <Nav areColorsInverted={props.props && props.props.areColorsInverted} {...props} />
      <Component changePage={props.changePage} {...props} />
    </Fragment>
  )
}

const mapStateToProps = (state) => ({
  isSettings: state.imageReducer.isSettings,
})

export default connect(
    mapStateToProps,
)(WithNav)