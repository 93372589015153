// Dependencies
import React, {useState} from 'react'
import {connect} from 'react-redux'

// Components
import {Input, Button} from '@anishp16/lazarus-cds'
// import {Input, Button} from 'lazarus-cds'

// Styles
import '@anishp16/lazarus-cds/dist/index.css'
import '../styles/Auth.css'
// import 'lazarus-cds/dist/index.css'

function SignInForm(props) {
  const [isForgotPassword, setIsForgotPassword] = useState(false)
  return (
    <div>
      <div>
        <Input
          label='Email'
          type='text'
          name='email'
          onChange={props.onChangeForm}
        />
      </div>
      <div>
        {!isForgotPassword &&
          <Input
            label='Password'
            type='password'
            name='password'
            onChange={props.onChangeForm}
          />
        }
        <p className='underline' onClick={() => setIsForgotPassword(!isForgotPassword)}>
          {isForgotPassword ? 'Back' : 'Forgot Password'}
        </p>
      </div>
      <Button
        text={isForgotPassword ? 'Receive email' : 'Enter'}
        onClick={
          isForgotPassword ?
          () => {
            props.sendPasswordResetEmail()
            setIsForgotPassword(!isForgotPassword)
          } :
          props.onClickSignIn
        }
        isLoading={props.isLoading}
      />
    </div>
  )
}

export default SignInForm