import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/database'
import {fetchPatientData} from '../fetchPatientData'
import {refreshSubData} from '../refreshSubData'
import {checkIfPatientExists} from './checkIfPatientExists'

export const signIn = (
    email,
    password,
    cookies, // To check if sign in failure
    firestore,
) => (dispatch) => {
  if (!password || !email) {
    dispatch({
      type: 'STORE_PAYLOAD',
      payload: {
        isLoading: false,
        userMessage: 'Please include email and password',
        notificationType: 1,
        notificationIcon: 'warning'
      },
    })
  } else {
    dispatch({
      type: 'STORE_PAYLOAD',
      payload: {
        isLoading: true,
      },
    })
    firebase.auth().signInWithEmailAndPassword(email, password)
        .then(signInSuccessCB)
        .catch(signInFailureCB)
  }

  /*  Response from successful signin is only stored if userData
      is successfully pulled. Userdata is stored along with signedIn/authStatus
      which triggers reroute
  */
  function signInSuccessCB(response) {
    var mixpanel = require('mixpanel-browser')
    mixpanel.identify(firebase.auth().currentUser.uid)
    mixpanel.track('User Signed In', {})
    const patientId = firebase.auth().currentUser.uid
    cookies.set('email', email, {path: '/'})
    cookies.set('password', password, {path: '/'})
    dispatch(checkIfPatientExists(cookies,firebase.auth(), firestore, patientId))
    // dispatch(fetchPatientData(cookies, firebase.auth(), firestore, [patientId])) // Takes an array of patient Ids
    // dispatch(refreshSubData(firestore, patientId))
    // console.log('firestore', firestore)
    // dispatch(fetchPatientData(firestore, ['1u98l7XFNhKQmBv0Ckep']))
  }

  function signInFailureCB(response) {
    console.log('sign in failure', response)
    const isCookie = cookies && cookies.get('email') && cookies.get('password')
    if (isCookie) { // No warning if cookies are wrong (changed password)
      dispatch({
        type: 'STORE_PAYLOAD',
        payload: {
          isLoading: false,
          // userMessage: 'You entered an incorrect email, password, or both',
          // notificationType: 3,
          // notificationIcon: 'warning',
        },
      })
    } else {
      dispatch({
        type: 'STORE_PAYLOAD',
        payload: {
          isLoading: false,
          userMessage: 'You entered an incorrect email, password, or both',
          notificationType: 3,
          notificationIcon: 'warning',
        },
      })
    }
    // console.log('sign in failure', response)
    dispatch({
      type: 'SIGN_IN',
      payload: {
        isLoading: false,
        isSignedIn: false,
        userMessage: 'You entered an incorrect email, password, or both',
        notificationType: 3,
        notificationIcon: 'warning',
        authStatus: 'failure',
        userData: null,
      },
    })
  }
}