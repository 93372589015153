import firebase from 'firebase/app';

export const signOut = () => (dispatch) => {
  firebase.auth().signOut()
      .then(() => {
        console.log('Signed out successfully')
        dispatch({
          type: 'STORE_PAYLOAD',
          payload: {
            isLoading: false,
            isSignedIn: false,
            isSettings: false,
            userMessage: null,
            userData: null,
            patientData: {},
          },
        })
      })
      .then(() => dispatch({type: 'CLEAR_STORE'}))
      .catch((error) => {
        console.log('Error on sign out', error)
      })
}
