import firebase from 'firebase/app'

export const refreshSubData = (firestore, patientId) => (dispatch) => {
  const getSubURL = process.env.REACT_APP_URL + 'sonnetStripeGetSubscription'
  firestore.collection('LCDS') // Fetch userData
      .doc('Something')
      .collection('Patients')
      .doc(patientId)
      .get()
      .then((res) => {
        const patientData = res.data()
        if (patientData["Sub"] && patientData["Sub"]["ID"]) {
            const myHeaders = new Headers();
            myHeaders.append("Content-Type", "application/json");

            const raw = JSON.stringify(
                {"patient_id": patientId},
            );
            const requestOptions = {
              method: 'POST',
              headers: myHeaders,
              body: raw,
              redirect: 'follow',
            };

            fetch(getSubURL, requestOptions)
              .then(response => {
                return response.json()
              })
              .then(res => {
                dispatch({
                  type: 'STORE_PAYLOAD',
                  payload: {
                    subscriptionData: {
                      status: res["status"],
                      subPlan: res["plan"]["id"],
                      subID: res["id"],
                      cusID: patientData["Stripe"]["ID"],
                      amount: res["items"]["data"][0]["plan"]["amount"],
                      interval: res["items"]["data"][0]["plan"]["interval"],
                      start: res["current_period_start"],
                      end: res["current_period_end"],
                      dig4: res["card"]["last4"],
                      brand: res["card"]["brand"],
                      type: res["card"]["funding"],
                    },
                  },
                })
              })
              .catch((error) => {
                dispatch({
                  type: 'STORE_PAYLOAD',
                  payload: {
                    // isSettings: true,
                    userMessage: 'There was an error getting your subscription info',
                    notificationType: '3',
                    notificationIcon: 'warning',
                    subscriptionData: {
                      status: "error",
                      subPlan: "error",
                      subID: "error",
                      cusID: "error"
                    },
                  },
                })
              })
        } else {
          dispatch({
            type: 'STORE_PAYLOAD',
            payload: {
              // isSettings: true,
              // userMessage: 'Get started by choosing a subscription plan.',
              // notificationType: '2',
              // notificationIcon: 'warning',
              subscriptionData: {
                status: "None",
                subPlan: "None",
                cusID: "None",
                amount: 0,
                dig4: "****",
                brand: "No",
                type: "",
              },
            },
          })
        }
      })
}
