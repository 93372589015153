import React, {Component, Fragment} from 'react'
import {Link} from 'react-router-dom'
import {connect} from 'react-redux'
import queryString from 'query-string'

import whitePlus from '../Images/WhitePlus.svg'
import notes from '../Images/notes.svg'
import flag from '../Images/flag.svg'
import Loading from './LoadingIcon'
import LoadingIcon2 from './LoadingIcon2'

import {storeTestSetAndImgIndex} from '../actions/storeTestSetAndImgIndexAction'
import {deleteTestSet} from '../actions/deleteTestSetAction'
import {storeSecret} from '../actions/storeSecretAction'
import {storeIsEditing} from '../actions/storeIsEditingAction'
import {fetchPatientData} from '../actions/fetchPatientData'
import {storePayload} from '../actions/storePayload'

// Components
import {
  FloatingButton,
} from '@anishp16/lazarus-cds'

import {Elements} from '@stripe/react-stripe-js'
import {loadStripe} from '@stripe/stripe-js'

import CheckoutForm from './InjectedCheckoutForm'
import '../styles/Collage.css'
import '@anishp16/lazarus-cds/dist/index.css'

// import moleTrackingJSON from '../Mole-Tracking.json'
import helpers from '../helpers'

/*
  Collage is the default component that renders if the user is signed in and has
  a subscription. It will render nav, test-upload-buttons, test thumbs (if they have any),
  or instructions (if they don't)
*/

class Collage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      batchId: null,
      submitModal: false,
      deleteModal: false,
      slideUpDelete: false,
      slideUpSubmit: false,
      slideUpCart: false,
      dropDown: {},
      testSetDropDown: -1, // index of testSet to show 'edit' and 'delete options
      isDoneLoading: true,
      secret: "",
      hasTests: false,
    }
  }

  componentDidMount() {
    this.props.storePayload({
      currentTest: null,
    })
    const resize = () => {
      // const root = document.getElementById('root')
      // const em = parseFloat(getComputedStyle(root).fontSize)
      // const navHeight = 3 * em
      // const collageHeight = `calc(100% - ${navSize}`
      const collageHeight = '100%'
      this.setState({collageHeight})
    }
    window.addEventListener('resize', resize)
  }

  UNSAFE_componentWillReceiveProps(props) {
    if (props.patientData) {
      if (props.patientData.tests && props.patientData.tests.length) {
        this.setState({hasTests: true})
      } else {
        this.setState({hasTests: false})
      }
    }
  }

  toggleModal() {
    this.setState({ modal: -1 })
  }

  handleDate(timeStamp) {
    const date = new Date(timeStamp)
    const hour = date.getHours().toString()
    const minutes = date.getMinutes().toString()
    const add0 = (str) => {
      if (str.length === 1) {
        return '0' + str
      } return str
    }
    const time = add0(hour) + ':' + add0(minutes)
    return time
  }

  getPaymentSecret(qty, state) {
    let self = this
    fetch(`https://us-central1-lazarus-cds.cloudfunctions.net/getPaymentIntent?qty=` + qty + `&state=` + state,
      {
        method: 'GET',
      }).then((res) => res.json()
      ).then((res) => {
        //console.log('payment secret success')
        if (res["status"] === "Success") {
          self.setState({
            secret: res["secret"]
          })
          this.props.storeSecret(res["secret"])
        } else {
          self.setState({
            secret: ""
          })
          this.props.storeSecret(res["secret"])
        }
      }).catch(() => {
        //console.log('payment secret failure')
        self.setState({
          secret: ""
        })
        this.props.storeSecret("")
      })
  }

  handleLoading = (state) => {
    this.setState({
      isDoneLoading: state
    })
  }

  render() {
    const app = document.getElementsByClassName('App')[0]
    const em = parseFloat(getComputedStyle(app).fontSize)
    const appWidth = app.offsetWidth
    const imgWidth = (appWidth - (3 * em)) / 4
    const {testSets} = this.props
    const testPrice = 50
    const extraTestPrice = 14
    const root = document.getElementById('root')
    const fontSize = parseFloat(getComputedStyle(root).fontSize)
    const contentWidth = document.getElementsByClassName('App')[0] ?
      document.getElementsByClassName('App')[0].offsetWidth : 0
    const btnStyle = { width: `${contentWidth - (fontSize * 2)}px` }
    let height = window.innerHeight

    const stripeThing = () => {
      const adminList = process.env.REACT_APP_ADMINS.split(' ')
      const isAdmin = adminList.some(admin => admin === queryString.parse(window.location.search).state)
      const key = isAdmin ? process.env.REACT_APP_STRIPE_TEST_KEY : process.env.REACT_APP_STRIPE_KEY
      return loadStripe(key)
    }
    return (
      (this.state.isDoneLoading) ? (
        <div
          className="collage"
          style={{height: '100%'}}
        >
          {!this.state.tests || this.state.tests.length === 0 &&
            <div
              id="grey-screen"
              style={{ width: contentWidth }}
            >
            </div>
          }
          {this.state.submitModal &&
            <Fragment>
              {true ?
              <div
                className={
                  this.state.slideUpSubmit ? "full-modal slideInUp" : "full-modal slideOutDown"
                }
                style={{ width: `${contentWidth}px` }}
              >
                <h1 className="text-align-center">Submit tests for review?</h1>
                <div>
                  <h3 className="underline text-align-center">This action cannot be undone.</h3>
                </div>
                <div className="vertical-btn-container">
                  <div
                    className="btn-div vertical-btn"
                    onClick={() => {
                      if (this.props.skipPayment) {
                        this.setState({ slideUpSubmit: false })
                        this.props.changePage('loadingScreenPage')
                      }
                      // this.props.changePage('loadingScreenPage')
                      else if (this.state.secret !== "") {
                        this.setState({ cartModal: true, slideUpCart: true, slideUpSubmit: false })
                      } else {
                        alert("There was an error getting to checkout, please try again later.")
                        this.getPaymentSecret(
                            null,
                            queryString.parse(window.location.search).state
                        )
                      }
                    }}
                  >
                    <p>Yes</p>
                  </div>
                  <div
                    className="btn-div vertical-btn"
                    onClick={() => this.setState({ slideUpSubmit: false })}
                  >
                    <p>No</p>
                  </div>
                </div>
              </div> :
              <LoadingIcon2 />
              }
            </Fragment>
          }

          {this.state.cartModal &&
            <div
              className={
                this.state.slideUpCart ? "full-modal cart-modal slideInUp" : "full-modal cart-modal slideOutDown"
              }
              style={{ width: `${contentWidth}px` }}
            >
              <h1 className="text-align-center">Your Cart</h1>
              <div className="cart-content flex-column space-evenly">
                <div className="flex space-between">
                  <span>Basic Test</span>
                  <span>{`$${testPrice}`}</span>
                </div>
                <div style={{ height: '10px', borderBottom: '1px solid black' }}></div>
                <div className="flex space-between">
                  <span>Total</span>
                  <span><strong>{`$${extraTests * extraTestPrice + testPrice}`}</strong></span>
                  {/* <span><strong>{`$${testPrice}`}</strong></span> */}
                </div>
              </div>
              <Elements stripe={stripeThing()}>
                <CheckoutForm 
                  secret={this.state.secret}
                  handleLoading = {this.handleLoading}
                  changePage={this.props.changePage}
                />
              </Elements>
              <div className="vertical-btn-container">
                <div
                  className="btn-div vertical-btn"
                  onClick={() => this.setState({ slideUpCart: false })}
                >
                  <p>Cancel</p>
                </div>
              </div>
            </div>
          }

          {this.state.deleteModal &&
            <div
              className={
                this.state.slideUpDelete ? "full-modal slideInUp" : "full-modal slideOutDown"
              }
              style={{ width: `${contentWidth}px` }}
            >
              <h1 className="text-align-center">Delete this test entry?</h1>
              <div>
                <h3 className="underline text-align-center">This action cannot be undone.</h3>
              </div>
              <div className="vertical-btn-container">
                <div
                  className="btn-div vertical-btn"
                  onClick={() => {
                    this.props.deleteTestSet(this.state.testSetDropDown)
                    this.setState({ slideUpDelete: false, testSetDropDown: -1 })
                  }}
                >
                  <p>Yes</p>
                </div>
                <div
                  className="btn-div vertical-btn"
                  onClick={() => this.setState({ slideUpDelete: false, dropDown: {} })}
                >
                  <p>No</p>
                </div>
              </div>
            </div>
          }
          <main className="photo-set-container">
            {this.props.patientData && this.props.patientData.tests && this.props.patientData.tests.length ?
              <Fragment>
                <div className="photo-collection">
                  {this.props.patientData.tests.map((itest, i) => {
                    const extraBoxLength = 4 - itest.Tests.length
                    const extraBoxArr = extraBoxLength > 0 ? new Array(extraBoxLength) : []
                    let indices = []
                    return (
                      <Link
                        to={`/${itest.Id}`}
                        onClick={() => this.props.storePayload({
                          currentTest: itest,
                          isTooltip: false,
                        })}
                        key={i}
                      >
                        <div
                          className='tracking-test'
                          key={i}>
                            <div className='top'>
                              <div>
                                <span>{itest.MoleLocation}</span>
                                <div>
                                  {itest.Flag && <img src={flag} alt='flag' />}
                                  {itest.Notes && <img src={notes} alt='notes' />}
                                </div>
                              </div>
                              <div className='middle'>
                                {itest.Tests.map((jtest, j) => {
                                  if (itest.Tests.length > 4) {
                                    const length = itest.Tests.length
                                    const interval = Math.round(length / 3)
                                    const secondTestIndex = interval
                                    const thirdTestIndex = length - interval
                                    indices = [0, secondTestIndex, thirdTestIndex, length - 1]
                                    if (indices.some((index) => index === j)) {
                                      return (
                                        <img style={{height: imgWidth, width: imgWidth}} key={j} src={jtest.ImageRef} />
                                      )
                                    }
                                  } else {
                                    return (
                                      <img style={{height: imgWidth, width: imgWidth}} key={j} src={jtest.ImageRef} />
                                    )
                                  }
                                })}
                                {itest.Tests.length < 4 &&
                                  <div>
                                    {[...extraBoxArr].map((box, k) => {
                                      if (k < extraBoxArr.length - 1 && k !== extraBoxArr.length - 2) {
                                        return (
                                          <div
                                            key={k}
                                            style={{
                                              height: imgWidth,
                                              width: imgWidth,
                                              boxSizing: 'border-box',
                                              border: '1px solid white',
                                              borderLeft: 'none',
                                              backgroundColor: 'rgb(144, 144, 144)',
                                            }}
                                          >
                                            <p style={{color: 'white'}}>

                                            </p>
                                          </div>
                                        )
                                      } else if (k === extraBoxArr.length - 2) {
                                        return (
                                          <div
                                            key={k}
                                            style={{
                                              height: imgWidth,
                                              width: imgWidth,
                                              boxSizing: 'border-box',
                                              border: '1px solid white',
                                              borderLeft: 'none',
                                              borderRight: 'none',
                                              backgroundColor: 'rgb(144, 144, 144)',
                                            }}
                                          >
                                            <p style={{color: 'white'}}>

                                            </p>
                                          </div>
                                        )
                                      } else {
                                        return (
                                          <div
                                            key={k}
                                            style={{
                                              height: imgWidth,
                                              width: imgWidth,
                                              boxSizing: 'border-box',
                                              border: '1px solid black',
                                              backgroundColor: 'rgb(204, 204, 204',
                                            }}
                                          >
                                          </div>
                                        )
                                      }
                                    })}
                                  </div>
                                }
                              </div>
                              <div className='bottom-top'>
                                {itest.Tests.length > 1 ?
                                  <Fragment>
                                    {itest.Tests.map((jtest, j) => {
                                      const isMiddleTest = itest.Tests.length < 5 || indices.some((index) => index === j)
                                      if (j === 0) {
                                        return (
                                          <div key={j}>
                                            <span>First</span>
                                          </div>
                                        )
                                      } else if (j === (itest.Tests.length - 1)) {
                                          return (
                                            <div key={j}>
                                              <span>Latest</span>
                                            </div>
                                          )
                                      } else if (isMiddleTest) {
                                          /* blank space for middle tests */
                                          return (
                                            <div key={j} />
                                          )
                                      }
                                    })}
                                  </Fragment> :
                                  <Fragment>
                                    <div style={{display: 'flex', justifyContent: 'space-between', width: '100%'}}>
                                      <span>First</span>
                                      <pre>Add Image to Timeline</pre>
                                    </div>
                                  </Fragment>
                              }
                              </div>
                              <div className='bottom'>
                                {
                                  itest.Tests.map((jtest, j) => {
                                  if (j === 0 || j === itest.Tests.length - 1) {
                                    return (
                                      <div className='date-div' key={j}>
                                        <p>{helpers.timeStampToDate(jtest.DateTaken)}</p>
                                      </div>
                                    )
                                  } else if (j < 3) {
                                    return (
                                      <div className='empty-div' key={j}>
                                        {/* Empty to avoid crowding */}
                                      </div>
                                    )
                                  }
                                })
                              }
                              </div>
                            </div>
                        </div>
                      </Link>
                    )
                  })}
                </div>
              </Fragment>
              :
              <Fragment>
                <p className='default-message'>Get started by creating your first timeline, using the "+" button below.</p>
              </Fragment>
            }
            {this.props.isTooltip && <div className='giant-circle' />}
            <footer>
              <FloatingButton
                image={whitePlus}
                style={{height: '3em', width: '3em'}}
                label={this.props.isTooltip ? 'Create New Timeline' : null}
                onClick={() => {
                  this.props.changePage('bodyPartPage')
                }}
              />
            </footer>
          </main>
          {/* <footer>
            <img
              src={whitePlus}
              alt="add"
              onClick={() => {
                this.props.changePage('bodyPartPage')
              }}
            />
          </footer> */}
        </div>
      ) :
        (<Fragment>
          <Loading />
        </Fragment>)

    )
  }
}

const mapStateToProps = (state) => ({
  storage: state.firebaseReducer.storage,
  database: state.firebaseReducer.database,
  firestore: state.firebaseReducer.firestore,
  patientData: state.imageReducer.patientData,
  secret: state.imageReducer.secret,
  skipPayment: state.imageReducer.skipPayment,
  userMessage: state.imageReducer.userMessage,
  isTooltip: state.imageReducer.isTooltip,
})

export default connect(
    mapStateToProps,
    {storeTestSetAndImgIndex,
    storeIsEditing,
    deleteTestSet,
    storeSecret,
    storePayload,
    fetchPatientData}
)(Collage)