import React from 'react';
import ReactDOM from 'react-dom';
import {Provider} from 'react-redux'
import {CookiesProvider} from 'react-cookie'
import {BrowserRouter as Router} from 'react-router-dom'
import store from './store'
import App from './components/App';

const rootElement = document.getElementById('root');

const routing = (
  <CookiesProvider>
    <Provider store={store}>
      <Router>
        <App />
      </Router>
    </Provider>
  </CookiesProvider>
)

ReactDOM.render(routing, rootElement);
