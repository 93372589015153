/*  Takes an array of patient Id's from 'Something/Organization/Patients' and
    fetches the patient data (tests) from 'Something/Patients
    Stores {patientId: {...res}} as patientsData in userReducer
*/

import moleTrackingJSON from '../Mole-Tracking.json'

export const fetchPatientData = (cookies, auth, firestore, patientIds, newTestId = null) => (dispatch) => {
  let patientData = {}
  patientIds.forEach(async (patientId) => {
    const patientDoc = await
    firestore.collection('LCDS')
        .doc('Something')
        .collection('Patients')
        .doc(patientId)
        .get()
    if (!patientDoc) {
      auth.signOut()
      cookies.remove('email', {path: '/'})
      cookies.remove('password', {path: '/'})
      dispatch({
        type: 'STORE_PAYLOAD',
        payload: {
          userMessage:
            'There was an error finding patient with Id: ' + patientId,
          notificationType: 3,
          notificationIcon: 'warning',
        },
      })

    }
    await firestore.collection('LCDS')
        .doc('Something')
        .collection('Patients')
        .doc(patientId)
        .collection('DermatologyTests')
        .where('Type', '==', 'Mole-Tracking')
        .onSnapshot((res) => {
          if (res.docs) {
            const patientTestDocs = res.docs
            const patientTests = patientTestDocs.map((doc) => {
              return (
                {...doc.data(), Id: doc.id}
              )
            })
            const data = patientDoc.data()
            // If multiple patients...
            // patientsData[patientId] = {tests: patientTests, data}
        
            // If one patient...
            // patientsData = {tests: patientTests, data}
            const sortedTests = patientTests.sort((a, b) => {
              if (a.LastUpdated && b.LastUpdated) {
                return b.LastUpdated - a.LastUpdated
              }
              return b.DateTaken - a.DateTaken // Legacy tests
            })
            patientData = {tests: sortedTests, data: data}
            // const patientsDataKeys = Object.keys(patientsData)
            /* After last test (check because async) */
            // if (patientIds.length === patientsDataKeys.length) {
            //   dispatch({
            //     type: types.STORE_PAYLOAD,
            //     payload: {
            //       patientsData,
            //     },
            //   })
            // }
            const payload = {
              patientData,
              isSignedIn: true,
              isLoading: false,
            }
            // if (newTestId) { // adding another image to timeline
            //   const newCurrentTest = patientData.tests.filter((iTest) => iTest.Id === newTestId)[0]
            //   payload.currentTest = newCurrentTest
            // }
            dispatch({
              type: 'STORE_PAYLOAD',
              payload: payload,
            })
          } else {
            patientData = {tests: [moleTrackingJSON, moleTrackingJSON], data: {...exampleData}}
            dispatch({
              type: 'STORE_PAYLOAD',
              payload: {
                userMessage: 'There was an error accessing your data. Please try again later.',
                notificationType: '3',
                notificationIcon: 'warning',
                patientData,
                isSignedIn: false,
                isLoading: false,
              },
            })
          }
        })
  })
}