// Dependencies
import React, {useState} from 'react'
import {connect} from 'react-redux'
import {withCookies} from 'react-cookie'
import {Link} from 'react-router-dom'

// Actions
import {signUp} from '../actions/Auth/signUp'
import {storePayload} from '../actions/storePayload'

// Components
import {Input, SexInput, Button, BirthdayForm} from '@anishp16/lazarus-cds'

// Styles
import '@anishp16/lazarus-cds/dist/index.css'
import '../styles/Auth.css'

/*
  Renders sign-up form inside of Auth.js
*/
function SignUpForm(props) {
  // const demoForm = {
  //   firstName: 'Person',
  //   lastName: 'McPerson',
  //   phone: 123,
  //   email: 'ericjbowman@yahoo.com',
  //   sex: 'Male',
  //   gender: 'Zir',
  //   password: 'Blah123',
  //   confirmPassword: 'Blah123',
  //   day: '01',
  //   month: '01',
  //   year: '1999',
  // }
  const [form, setForm] = useState({})
  const [isGenderInformationModal, setIsGenderInformationModal] = useState(false)

  const onChangeForm = (event, name, value) => {
    if (event) {
      setForm({
        ...form,
        [event.target.name]: event.target.value,
      })
    } else {
      setForm({
        ...form,
        [name]: value,
      })
    }
  }

  const onChangeSex = (option) => {
    setForm({
      ...form,
      sex: option,
    })
  }

  const signUpClick = () => {
    props.signUp(form, props.firestore, props.cookies)
  }

  return (
    <div>
      <Input
        label='First Name'
        type='text'
        name='firstName'
        onChange={onChangeForm}
      />
      <Input
        label='Last Name'
        type='text'
        name='lastName'
        onChange={onChangeForm}
      />
      <div className='sex-input-container'>
        <SexInput
          // style={{width: '48%'}}
          sex={form.sex}
          onChange={onChangeSex}
          disabled={false}
          value={form.sex || undefined}
        />
        <p
          className='gender-information'
          onClick={() => setIsGenderInformationModal(!isGenderInformationModal)}
        >
          + Gender Information
        </p>
        {isGenderInformationModal &&
          <div className='gender-information-modal'>
            <Input
              label=''
              name='gender'
              disabled={props.isTestRequest || props.isSummary}
              onChange={onChangeForm}
              value={props.gender || undefined}
            />
          </div>
        }
      </div>
      <Input
        label='Contact E-mail'
        type='text'
        name='email'
        onChange={onChangeForm}
      />
      <Input
        label='phone'
        type='text'
        name='phone'
        onChange={onChangeForm}
      />
      <BirthdayForm
        onChangeDay={onChangeForm}
        onChangeMonth={onChangeForm}
        onChangeYear={onChangeForm}
      />
      <Input
        label='Password'
        type='password'
        name='password'
        onChange={onChangeForm}
        width='50%'
      />
      <Input
        label='Confirm Password'
        type='password'
        name='confirmPassword'
        onChange={onChangeForm}
        width='50%'
      />
      <div>
        <Button
          text='Sign Up'
          onClick={signUpClick}
          type={1} // White Button
        />
      </div>
    </div>
  )
}


const mapStateToProps = (state, ownProps) => ({
  firestore: state.firebaseReducer.firestore,
  cookies: ownProps.cookies,
})

export default withCookies(connect(
    mapStateToProps,
    {signUp, storePayload},
)(SignUpForm))