import { firestore } from 'firebase'

export const submitTest = (patientData, test, storage, patientID, notes = null, testId = null) => (dispatch) => {
  let dataToSend
  const mixpanel = require('mixpanel-browser')
  // console.log('test', test)
  const testCreateURL = process.env.REACT_APP_URL + 'sonnetTestCreate'
  dispatch({
    type: 'STORE_PAYLOAD',
    payload: {
      isLoading: true,
    },
  })
  /* If testId, api will append data to an existing Mole-Tracking test */
  const uuidv4 = require('uuid/v4')
  const storageRef = storage.ref("Tests")
  let testname = uuidv4()
  function handleError() {
    mixpanel.track('Submit Test Failure', dataToSend)
    dispatch({
      type: 'STORE_PAYLOAD',
      payload: {
        userMessage: 'There was an error uploading your images',
        notificationType: 3,
        notificationIcon: 'warning',
        isLoading: false,
      },
    })
  }
  storageRef.child(testname).put(test.image.croppedImageFile)
      .then((snapshot) => {
        snapshot.ref.getDownloadURL()
            .then((downloadURL) => {
              const orgId = patientData.data.Linked ? patientData.data.Linked.Main : null
              dataToSend = {
                image: downloadURL,
                patient_id: patientID,
                test_id: testId,
                orgId: orgId,
                notes: notes,
                MoleCoordinates: test.moleCoordinates,
                MoleLocation: test.moleLocation,
                Front: test.front,
              }
              postTestData(dataToSend)
            })
            .catch((error) => {
              console.error(error)
              handleError()
            })
      })
      .catch((error) => {
        console.error(error)
        handleError()
      })
  /* Backend posts images to patient firestore doc with AI results */
  function postTestData(dataToSend) {
    const jsonData = JSON.stringify(dataToSend)
    fetch(testCreateURL, {
      method: 'POST',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
      },
      body: jsonData,
    })
        .then((res) => {
          if (res.ok) {
            mixpanel.track('Submit Test Success', dataToSend)
            let newTestId
            res.json()
              .then((data) => {
                if (data.test_id) {
                  // console.log('test id', data.test_id)
                  newTestId = data.test_id
                  const payload = {
                    userMessage: 'Image has been successfully uploaded',
                    notificationType: 2,
                    notificationIcon: 'check',
                    newTestId: newTestId,
                    isLoading: false,
                  }
                  // if (testId) { // adding another image to timeline
                  //   console.log('adding image to timeline', testId)
                  //   const newCurrentTest = patientData.tests.filter((iTest) => iTest.Id === newTestId)[0]
                  //   payload.currentTest = newCurrentTest
                  // }
                  dispatch({
                    type: 'STORE_PAYLOAD',
                    payload: payload,
                  })
                }
              })
              .catch((error) => console.log('error', error))
          } else {
            handleError()
          }
        })
        .catch((error) => {
          console.error(error)
          handleError()
        })
  }
}