const helpers = {
  timeStampToDate(timestamp) {
    let t = timestamp
    if (timestamp.toString().length === 10) {
      t = timestamp * 1000
    }
    const dateObj = new Date(t)
    return (
      dateObj.getMonth() + 1 +
      '/' + dateObj.getDate() +
      '/' + dateObj.getFullYear()
    )
  },
  timeStampToTime(timestamp) {
    const dateObj = new Date(timestamp)
    const hours = dateObj.getHours().toString()
    const minutes = dateObj.getMinutes().toString()
    const formattedHours = hours.length === 2 ? hours : '0' + hours
    const formattedMinutes = minutes.length === 2 ? minutes : '0' + minutes
    return (
      `${formattedHours}:${formattedMinutes}`
    )
  },
  async postData(url = '', data = {}) {
    const response = await fetch(url, {
      method: 'POST',
      cache: 'no-cache',
      credentials: 'same-origin',
      headers: {
        'Content-Type': 'application/json',
        // 'Content-Type': 'application/x-www-form-urlencoded',
      },
      redirect: 'follow',
      referrerPolicy: 'no-referrer',
      body: JSON.stringify(data),
    }).catch((error) => {
      // console.log(error)
    })
    return response.json()
  },
  detectApple() {
    const toMatch = [
      /webOS/i,
      /iPhone/i,
      /iPad/i,
      /iPod/i,
    ];

    return toMatch.some((toMatchItem) => {
      return navigator.userAgent.match(toMatchItem);
    })
  },
  loadImageCB(imgCanvas) {
    const blobPromise = new Promise((resolve, reject) => {
      if (imgCanvas.height < 480 || imgCanvas.width < 480) {
        // alert("Warning: Image should be at least 480 x 480 pixels")
        this.setState({tooSmall: true})
      } else {
        this.setState({tooSmall: false})
      }
      imgCanvas.toBlob((blob) => {
        if (!blob) {
          console.error('Canvas is empty')
          return;
        }
        blob.name = 'fileName'
        this.fileUrl = window.URL.createObjectURL(blob)
        resolve([this.fileUrl, blob])
      }, 'image/jpeg')
    });

    blobPromise
        .then((imageArr) => {
          this.setState({
            front: !this.state.front,
            isImageSelected: true,
            imageUrl: imageArr[0],
            imageFile: imageArr[1],
            isImageCropped: false,
            showCollage: false,
          })
        })
  },

  getCroppedImg(image, crop, fileName) {
    const canvas = document.createElement('canvas')
    const maxWidth = 960

    const shrinkRatio = maxWidth / image.naturalWidth
    const scaleX = image.naturalWidth / image.width
    const scaleY = image.naturalHeight / image.height

    if (image.naturalWidth > maxWidth) {
      canvas.width = Math.floor(crop.width * scaleX * shrinkRatio)
      canvas.height = Math.floor(crop.height * scaleY * shrinkRatio)
    } else {
      canvas.width = Math.floor(crop.width * scaleX)
      canvas.height = Math.floor(crop.height * scaleY)
    }
    const ctx = canvas.getContext('2d')

    /* Math for guaranteeing minimum size of 480 x 480 */
    let minWidth

    if (image.naturalWidth > maxWidth) {
      minWidth = 480 / scaleX / shrinkRatio
      ctx.drawImage(
          image,
          crop.x * scaleX,
          crop.y * scaleY,
          crop.width * scaleX,
          crop.height * scaleY,
          0,
          0,
          crop.width * scaleX * shrinkRatio,
          crop.height * scaleY * shrinkRatio,
      );
    } else {
      minWidth = 480 / scaleX
      ctx.drawImage(
          image,
          crop.x * scaleX,
          crop.y * scaleY,
          crop.width * scaleX,
          crop.height * scaleY,
          0,
          0,
          crop.width * scaleX,
          crop.height * scaleY,
      );
    }
    this.setState({minWidth, canvas})

    return new Promise((resolve, reject) => {
      canvas.toBlob((blob) => {
        if (!blob) {
          reject(new Error('Canvas is empty'))
        }
        blob.name = fileName
        this.fileUrl = window.URL.createObjectURL(blob)
        resolve([this.fileUrl, blob])
      }, 'image/jpeg')
    })
  },
}

export default helpers
