import React, {Fragment, Component} from 'react'
import {connect} from 'react-redux'
import loadImage from 'blueimp-load-image'
import ReactCrop from 'react-image-crop'
import helpers from '../helpers.js'

/*  Zoomed in (2nd) body part selection screen
    Rendered from BodyPart.js
*/

class BodyPart2 extends Component {
  constructor(props) {
    super(props);

    this.state = {
      gender: 'female',
      front: true,
      cropHasBeenAdjusted: false,
      crop: {
        unit: '%',
        // imgRatio: 1.45667,
        trueRatio: this.props.trueRatio,
        // height: 17.1624, // width / imgRatio = height
        height: 25 / this.props.trueRatio,
        width: 25,
        // aspect: 1 / 1,
        x: 37.5,
        // y: 41.4188 // (100 - height) / 2,
        y: (100 - (25 / this.props.trueRatio)) / 2,
      },

      minWidth: null,
      maxWidth: null,
      isImageSelected: false,
      imageFile: [],
      imageUrl: [],
      croppedImageFiles: [],
      croppedImageUrls: [],
      croppedImageFile: null,
      croppedImageUrl: null,
      isImageCropped: false,

      loading: false,
      tooSmall: false,
      showCollage: false,
    }

    this.setImage = this.setImage.bind(this)
    this.onImageLoaded = this.onImageLoaded.bind(this)
    this.onCropChange = this.onCropChange.bind(this)
  }

  setImage() {
    loadImage(
        this.props.croppedImageUrl,
        helpers.loadImageCB.bind(this),
        {orientation: true},
    )
  }

  onImageLoaded(image) {
    this.imageRef = image
  }

  onCropChange(crop, percentCrop) {
    // this.handleSelecting(percentCrop)
    this.setState({crop: percentCrop})
    const centerX = percentCrop.x + (percentCrop.width / 2)
    const centerY = percentCrop.y + (percentCrop.height / 2)

    this.props.adjustCoordinatesFromZoom(centerX, centerY)
  }

  render() {
    const cropper = !this.state.isImageCropped ?
      <ReactCrop
        className='crop-image'
        src={this.props.croppedImageUrl}
        crop={this.state.crop}
        ruleOfThirds={false}
        locked={true}
        onImageLoaded={this.onImageLoaded}
        onChange={this.onCropChange}
        minWidth={this.state.minWidth}
        minHeight={this.state.minWidth}
        maxWidth={this.state.maxWidth}
        maxHeight={this.state.maxHeight}
        keepSelection={true}
        circularCrop={true}
      /> :
      <img className='crop-image' src={this.state.croppedImageUrl} alt=""></img>

    const cropperContainer = (
      <div className="cropper-container">
        <div className="image-container zoomed-in">
          {cropper}
        </div>
      </div>
    )

    return (
      <Fragment>
        {cropperContainer}
      </Fragment>
    )
  }
}

const mapStateToProps = (state) => ({
  storage: state.firebaseReducer.storage,
  database: state.firebaseReducer.database,
  firestore: state.firebaseReducer.firestore,
  testSets: state.imageReducer.testSets,
})

export default connect(mapStateToProps)(BodyPart2)
